import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoSocialShareData, SeoSocialShareService } from 'ngx-seo';

declare let gtag: Function;

export interface SeoData {
  author?: string;
  description: string;
  image?: SeoImageData;
  keywords?: string;
  modified?: string;
  product?: SeoProductData;
  published?: string;
  section?: string;
  title: string;
  // type will be inferred from the other metadata
  url?: string;
}

export interface SeoImageData {
  path: string;
  type?: 'absolute' | 'relative';
  dimensions?: SeoImageDimensions;
}

export interface SeoImageDimensions {
  height: number;
  width: number;
}

export interface SeoProductData {
  price: number;
  currency?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private _defaultAuthor = 'Tellibus';

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject('PUBLIC_URL') private readonly publicUrl: string,
    private readonly meta: Meta,
    @Inject(PLATFORM_ID) private platformId: any,
    private readonly seoSocialShareService: SeoSocialShareService,
    private readonly title: Title,
  ) {}

  /**
   * @returns string
   */
  private get defaultAuthor(): string {
    return this._defaultAuthor;
  }

  /**
   * @returns string
   */
  private get defaultImage(): SeoImageData {
    return {
      path: `${this.publicUrl}/assets/layout/images/logo-dark.svg`,
      type: 'absolute',
      dimensions: { height: 820, width: 1200 },
    };
  }

  /**
   * @returns string
   */
  private getDefaultImageDimensions(): SeoImageDimensions {
    return {
      height: 500,
      width: 500,
    };
  }

  private getNotificationsCount(): number {
    let notificationCount = 0;
    const title = this.title.getTitle();
    const titleWithNotifications = title.match(/\((\d+)\) .+/);

    if (titleWithNotifications) {
      notificationCount = parseInt(titleWithNotifications[1], 10);
    }

    return notificationCount;
  }

  private getTitleWithoutNotifications(): string {
    let title = this.title.getTitle();
    const titleWithNotifications = title.match(/\(\d+\) (.+)/);

    if (titleWithNotifications) {
      title = titleWithNotifications[1];
    }

    return title;
  }

  /**
   * @returns string
   */
  private getUrl(): string {
    return this.document.URL;
  }

  registerAuthor(value: string) {
    this._defaultAuthor = value;
  }

  /**
   * Set all metadata
   *
   * @param  {SeoData} seo
   * @returns void
   */
  setAll(seo: SeoData): void {
    // Preserve notifications, if any
    const notificationsCount = this.getNotificationsCount();

    let type: 'product' | 'website';

    if (seo.product) {
      type = 'product';
    } else {
      type = 'website';
    }

    if (!seo.author) {
      seo.author = this.defaultAuthor;
    }

    if (!seo.image) {
      seo.image = this.defaultImage;
    }

    if (!seo.url) {
      seo.url = this.getUrl();
    }

    if (!seo.image) {
      seo.image = this.defaultImage;
    }

    if (!seo.image.dimensions) {
      seo.image.dimensions = this.getDefaultImageDimensions();
    }

    const image =
      (seo.image.type === 'absolute' ? '' : `${this.publicUrl}/`) +
      seo.image.path;

    const seoSocialData: SeoSocialShareData = {
      ...seo,
      image,
      type,
    };

    // Real SEO data
    this.seoSocialShareService.setData(seoSocialData);

    // Then title with notifications, if any
    this.updateTitleNotificationsCount(notificationsCount, seo.title);
  }

  setTitle(title: string): void {
    // Preserve notifications, if any
    const notificationsCount = this.getNotificationsCount();

    // Real SEO data
    this.title.setTitle(title);

    // Then title with notifications, if any
    this.updateTitleNotificationsCount(notificationsCount, title);
  }

  updateTitleNotificationsCount(newCount: number, title?: string) {
    if (!title) {
      title = this.getTitleWithoutNotifications();
    }

    if (newCount > 0) {
      this.title.setTitle(`(${newCount}) ${title}`);
    } else {
      this.title.setTitle(title);
    }
  }
}
