import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationService } from '@be-green/ui-services';
import {
  Observable,
  catchError,
  first,
  share,
  switchMap,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject('APP_SCOPE') private readonly appScope: string,
    private authenticationService: AuthenticationService,
  ) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const message =
          typeof err.error === 'string'
            ? err.error
            : err.error?.message || err.error?.error;

        const reason =
          typeof err.error === 'string' ? err.statusText : err.error?.error;

        if (
          err.status === 401 &&
          !request.url.includes('/auth/device') &&
          !request.url.includes('/auth/login') &&
          !request.url.includes('/auth/token')
        ) {
          // refresh token if 401 response returned from api unless we’re in login page already
          return this.authenticationService.renewToken().pipe(
            first(),
            share(),
            switchMap((newAccessToken) => {
              // refresh succeeded
              // resend the request with the new cookie-based token
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${newAccessToken}`,
                },
              });

              return next.handle(request);
            }),
          );
        } else if (err.status === 403) {
          /**
           * in case of 403 http errors:
           * - refresh token failed
           * - access not allowed but not catched client-side (change of roles, bug...)
           */
          if (!request.url.includes('/auth/token')) {
            // attempt to logout properly and redirect to login page
            this.authenticationService.logout().subscribe();
          } else {
            // clear local storage and redirect to login page
            this.authenticationService.clearStorage();
            location.reload();
          }
        }

        return throwError(() => {
          return {
            error: { message, reason },
            status: err.status,
          };
        });
      }),
    );
  }
}

export const errorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
