import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryFilterDto, QuerySortOrder, QueryUiDto } from '@be-green/dto';
import getVideoId from 'get-video-id';
import urlParser from 'js-video-url-parser';
import { LazyLoadEvent } from 'primeng/api';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  static capitalize(s: string) {
    if (typeof s !== 'string') return '';

    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  static convertTimeStringToAmPm(str: string): string {
    const [hours, minutes] = str.split(':');
    const hoursAsNumber = parseInt(hours, 10);
    const suffix = hoursAsNumber >= 12 ? 'pm' : 'am';

    return `${(((hoursAsNumber % 12) + 11) % 12) + 1}:${minutes
      .toString()
      .padStart(2, '0')} ${suffix}`;
  }

  static createDateFromDdMmYyyy(str: string): Date | null {
    if (!str) {
      return null;
    }

    const isValidDate = (str: string) => {
      const [day, month, year] = str
        .split('/')
        .map((n: string) => parseInt(n, 10));
      const parsed = new Date(year, month - 1, day);

      if (
        day === parsed.getDate() &&
        month === parsed.getMonth() + 1 &&
        year === parsed.getFullYear()
      ) {
        return parsed;
      } else {
        return false;
      }
    };

    const parsedDate = isValidDate(str);

    if (parsedDate !== false) {
      return parsedDate;
    } else {
      return null;
    }
  }

  static createDateFromYyyyDdMm(str: string): Date | null {
    if (!str) {
      return null;
    }

    const isValidDate = (str: string) => {
      const [year, month, day] = str
        .split('-')
        .map((n: string) => parseInt(n, 10));
      const parsed = new Date(year, month - 1, day);

      if (
        day === parsed.getDate() &&
        month === parsed.getMonth() + 1 &&
        year === parsed.getFullYear()
      ) {
        return parsed;
      } else {
        return false;
      }
    };

    const parsedDate = isValidDate(str);

    if (parsedDate !== false) {
      return parsedDate;
    } else {
      return null;
    }
  }

  static createDateFromYyyyDdMmHhIi(str: string): Date | null {
    if (!str) {
      return null;
    }

    const isValidDate = (str: string) => {
      const [date, time] = str.split('T');
      const [year, month, day] = date
        .split('-')
        .map((n: string) => parseInt(n, 10));
      const [hours, minutes] = time
        .split(':')
        .map((n: string) => parseInt(n, 10));
      const parsed = new Date(year, month - 1, day, hours, minutes);

      if (
        day === parsed.getDate() &&
        month === parsed.getMonth() + 1 &&
        year === parsed.getFullYear() &&
        hours === parsed.getHours() &&
        minutes === parsed.getMinutes()
      ) {
        return parsed;
      } else {
        return false;
      }
    };

    const parsedDate = isValidDate(str);

    if (parsedDate !== false) {
      return parsedDate;
    } else {
      return null;
    }
  }

  static deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static existsValueInEnum(type: any, value: any): boolean {
    return (
      Object.keys(type)
        .filter((k) => isNaN(Number(k)))
        .find((k) => type[k] === value) !== undefined
    );
  }

  static fromLazyLoadEventTooQueryUiDto(
    lazyLoadEvent: LazyLoadEvent,
    defaultPageSize: number,
  ): QueryUiDto {
    const queryUiDto: QueryUiDto = {};

    if (lazyLoadEvent.filters) {
      queryUiDto.filters = lazyLoadEvent.filters as {
        [s: string]: QueryFilterDto;
      };
    }

    if (lazyLoadEvent.first !== undefined) {
      queryUiDto.page =
        1 + lazyLoadEvent.first / (lazyLoadEvent.rows || defaultPageSize);
    }

    queryUiDto.results = lazyLoadEvent.rows || defaultPageSize;

    if (lazyLoadEvent.sortField) {
      queryUiDto.sortField = lazyLoadEvent.sortField;
    }

    if (lazyLoadEvent.sortOrder) {
      queryUiDto.sortOrder =
        lazyLoadEvent.sortOrder === 1
          ? QuerySortOrder.Ascend
          : QuerySortOrder.Descend;
    }

    return queryUiDto;
  }

  static fromQueryUiDtoToHttpParams(queryUiDto: QueryUiDto): HttpParams {
    let httpParams = new HttpParams();

    if (queryUiDto.page && queryUiDto.page > 0) {
      httpParams = httpParams.append('page', queryUiDto.page);
    }

    if (queryUiDto.results && queryUiDto.results > 0) {
      httpParams = httpParams.append('results', queryUiDto.results);
    }

    if (queryUiDto.sortField) {
      httpParams = httpParams.append('sortField', queryUiDto.sortField);
    }

    if (queryUiDto.sortOrder) {
      httpParams = httpParams.append('sortOrder', queryUiDto.sortOrder);
    }

    if (queryUiDto.filters) {
      const finalFilters: {
        [s: string]: QueryFilterDto;
      } = {};

      const mappedFilters = queryUiDto.filters as {
        [s: string]: QueryFilterDto;
      };

      for (const filter in mappedFilters) {
        if (
          mappedFilters[filter].value !== null &&
          mappedFilters[filter].value !== undefined
        ) {
          finalFilters[filter] = mappedFilters[filter];
        }
      }

      if (Object.keys(finalFilters).length) {
        httpParams = httpParams.append('filters', JSON.stringify(finalFilters));
      }
    }

    return httpParams;
  }

  /**
   * Tries to parse a valid video Url
   * then returns the corresponding embed link
   * @param rawUrl string
   * @returns string
   */
  static generateEmbedUrlFromVideoUrl(rawUrl: string): string | undefined {
    if (!rawUrl) {
      return undefined;
    }

    const videoInfo = urlParser.parse(rawUrl);

    if (videoInfo) {
      return urlParser.create({ videoInfo, format: 'embed' });
    } else {
      const secondTry = getVideoId(rawUrl);

      if (secondTry && secondTry.id && secondTry.service) {
        return urlParser.create({
          videoInfo: {
            id: secondTry.id,
            mediaType: 'video',
            provider: secondTry.service,
          },
          format: 'embed',
        });
      } else {
        return undefined;
      }
    }
  }

  static generateRandomHexColorForBackgroundWithWhiteText(): string {
    const hexToRgb = (
      hexColor: string,
    ): { r: number; g: number; b: number } => {
      const hex = hexColor.replace(/^#/, '');
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    };

    const minContrastRatio = 1.45;

    let hexColor = '';
    let contrastRatio = 0;

    while (contrastRatio < minContrastRatio) {
      hexColor = Math.floor(Math.random() * 16777215).toString(16); // generate a random hex color
      hexColor = '#' + '0'.repeat(6 - hexColor.length) + hexColor; // pad with zeros if necessary

      const rgbColor = hexToRgb(hexColor);

      const yiq =
        (rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000;
      const whiteContrast = (yiq + 255) / 256; // calculate contrast with white
      contrastRatio = Math.max(whiteContrast, 1 / whiteContrast); // calculate contrast ratio
    }

    return hexColor;
  }

  static generateUnambiguousRandomString(length: 4 | 8 | 12 = 8): string {
    const pattern = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ';
    const patternLength = pattern.length;
    let result = '';

    for (let i = 0; i < length; i++) {
      result += pattern[this.randomInt(0, patternLength - 1)];
    }

    return result;
  }

  static getDistanceFromLatLon(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ): { value: number; unit: 'm' | 'km' } {
    const distanceInMeters = UtilsService.getDistanceFromLatLonInMeters(
      lat1,
      lon1,
      lat2,
      lon2,
    );
    if (distanceInMeters < 1000) {
      return { value: distanceInMeters, unit: 'm' };
    } else {
      return { value: distanceInMeters / 1000, unit: 'km' };
    }
  }

  static getDistanceFromLatLonInMeters(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ): number {
    // Radius of the earth in km
    const earthRadius = 6371000;
    const dLat = UtilsService.deg2rad(lat2 - lat1);
    const dLon = UtilsService.deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(UtilsService.deg2rad(lat1)) *
        Math.cos(UtilsService.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadius * c; // Distance in km
  }

  static getFilenameExtension(filename: string) {
    return filename.lastIndexOf('.') < 1
      ? null
      : '.' + filename.split('.').slice(-1)[0].toLowerCase();
  }

  static getFilenameFromResponseHeaders(blob: Blob): string | null {
    const contentDispositionHeader = blob.type ? blob.type : '';
    const matches = contentDispositionHeader.match(/filename="(.*?)"/);

    if (matches && matches.length > 1) {
      return matches[1];
    } else {
      return null;
    }
  }

  static getFilenameWithoutExtension(filename: string) {
    return filename.lastIndexOf('.') < 1
      ? filename
      : filename.split('.').slice(0, -1).join('.');
  }

  static getHumanFileSize(size: number) {
    const i = Math.floor(Math.log(size) / Math.log(1024));

    return (
      (size / Math.pow(1024, i)).toFixed(2) +
      ' ' +
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    );
  }

  static getGravatarUrl(email: string, size = 96): string {
    return `https://gravatar.com/avatar/${UtilsService.md5(
      email,
    )}?s=${size}&d=identicon&f=y`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getKeyOfEnumValue(type: any, value: any): string | undefined {
    return Object.keys(type)
      .filter((k) => isNaN(Number(k)))
      .find((k) => type[k] === value);
  }

  static getUserTimeZone(
    padStart = false,
    padEnd = false,
  ): { name: string; offset: string } {
    const timeZoneOffset = new Date().getTimezoneOffset() / 60;

    let tzOffset = timeZoneOffset > 0 ? '-' : '+';
    tzOffset += padStart && timeZoneOffset < 10 ? '0' : '';

    if (padEnd) {
      tzOffset += Math.abs(timeZoneOffset).toFixed(2).replace('.', ':');
    } else {
      tzOffset += Math.abs(timeZoneOffset);
    }

    return {
      name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offset: tzOffset,
    };
  }

  static md5(str: string): string {
    return new Md5().appendStr(str.trim().toLowerCase()).end() as string;
  }

  /**
   * Obfuscates an email address by keeping the first 2 characters
   * and last character before the '@' symbol,
   * and replacing all other characters with asterisks.
   *
   * @param {string} email - The email address to obfuscate.
   * @returns {string} The obfuscated email address.
   */
  static obfuscateEmail(email: string): string {
    const atIndex = email.indexOf('@');
    if (atIndex === -1) {
      return email; // not a valid email
    }

    const username = email.substring(0, atIndex);
    const firstTwoChars = username.substring(0, 2);
    const lastChar = username.charAt(username.length - 1);
    const obfuscatedChars = username
      .substring(2, username.length - 1)
      .replace(/./g, '*');
    const domain = email.substring(atIndex);
    return `${firstTwoChars}${obfuscatedChars}${lastChar}${domain}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static parseJson(entry: unknown): any {
    try {
      return JSON.parse(<string>entry);
    } catch (e) {
      return entry;
    }
  }

  static randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
