import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { ProgramLocalizedDto } from '../programs';
import { PersonDto, UserDto } from '../users';

export class ParticipationLocalizedDto {
  @ValidateNested()
  @Type(() => PersonDto)
  readonly person!: PersonDto;

  @ValidateNested()
  @Type(() => ProgramLocalizedDto)
  @IsNotEmpty()
  readonly program!: ProgramLocalizedDto;

  @IsOptional()
  readonly referralCode?: string;

  @IsOptional()
  readonly referralCount?: number;

  @ValidateNested()
  @Type(() => ParticipationLocalizedDto)
  @IsOptional()
  readonly referrerParticipation?: ParticipationLocalizedDto;

  @IsOptional()
  readonly points?: number;

  @IsOptional()
  readonly quantity?: number;

  @IsOptional()
  readonly weight?: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;
}
