import { IsNotEmpty, IsOptional } from 'class-validator';

export class TipLocalizedDto {
  @IsOptional()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly category?: any;

  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly name!: string;

  @IsNotEmpty()
  readonly content!: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  checked?: boolean;
}
