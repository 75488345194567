import { Injectable } from '@angular/core';
import { BasketDto, LanguageCode } from '@be-green/dto';
import { distinctUntilChanged, tap } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { BiometryService } from '../biometry/biometry.service';
import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private isOnboardingDoneKey = 'begreen__is_onboarding_done';
  private languageCodeKey = 'begreen__language_code';
  private keys = {
    email: 'begreen__email',
    baskets: 'begreen__baskets',
  };

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly biometryService: BiometryService,
    private readonly storageService: StorageService,
  ) {
    this.setOnboardingDone();
  }

  get isOnboardingDone(): boolean {
    return this.storageService.get(this.isOnboardingDoneKey) === 'true';
  }

  get storedBaskets(): BasketDto[] {
    const value = this.storageService.get(this.keys.baskets);

    if (value) {
      return UtilsService.parseJson(value);
    } else {
      return [];
    }
  }

  get storedEmail(): string {
    if (
      this.authenticationService.decodedToken &&
      this.authenticationService.decodedToken.sub
    ) {
      return this.authenticationService.decodedToken.sub;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.storageService.get(this.keys.email)!;
    }
  }

  get storedLanguage(): LanguageCode {
    return (
      (this.storageService.get(this.languageCodeKey) as LanguageCode) || ''
    );
  }

  clearLocalStorage() {
    for (const value of Object.values(this.keys)) {
      this.storageService.remove(value);
    }
  }

  init() {
    this.authenticationService.isAuthenticated$
      .pipe(
        distinctUntilChanged(),
        tap((isAuthenticated) => {
          if (
            !isAuthenticated &&
            !this.biometryService.isBiometricAuthDesired
          ) {
            this.clearLocalStorage();
          }
        }),
      )
      .subscribe();
  }

  removeBasket(basketCode: string) {
    const baskets = this.storedBaskets;

    const storedBasketIndex = baskets.findIndex(
      (storedBasket) => storedBasket.code === basketCode,
    );

    baskets.splice(storedBasketIndex, 1);

    this.storageService.set(this.keys.baskets, baskets);
  }

  setOnboardingDone() {
    this.storageService.set(this.isOnboardingDoneKey, true);
  }

  storeLanguage(code: LanguageCode): void {
    this.storageService.set(this.languageCodeKey, code);
  }

  upsertBasket(basket: BasketDto): number | null | undefined {
    const baskets = this.storedBaskets;

    let previousLocalNotificationId = null;

    const storedBasketIndex = baskets.findIndex(
      (storedBasket) => storedBasket.code === basket.code,
    );

    if (storedBasketIndex > -1) {
      previousLocalNotificationId =
        baskets[storedBasketIndex].localNotificationId;

      baskets.splice(storedBasketIndex, 1);
    }

    this.storageService.set(this.keys.baskets, [basket, ...baskets]);

    return previousLocalNotificationId;
  }
}
