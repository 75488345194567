import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { UserDto } from '../users';

export const enum StaticContentPage {
  FrequentlyAskedQuestions = 'faq',
  TermsAndConditions = 'terms',
}

export class StaticContentDto {
  @IsNotEmpty()
  readonly page!: StaticContentPage;

  @IsNotEmpty()
  readonly contentFr!: string;

  @IsOptional()
  readonly contentAr?: string;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;
}
