import { Type } from 'class-transformer';
import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
} from 'class-validator';
import { UserRole } from '../users';
import { GiftFormField, GiftType } from './gift.dto';
import { ProgramLocalizedDto } from './program-localized.dto';

export class GiftLocalizedDto {
  @IsOptional()
  @ValidateNested()
  @Type(() => ProgramLocalizedDto)
  @IsOptional()
  readonly program?: ProgramLocalizedDto;

  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly name!: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  @IsOptional()
  readonly restrictedToRole?: UserRole;

  @IsOptional()
  readonly allowedRedemptions?: number;

  @IsNotEmpty()
  readonly type!: GiftType;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => GiftFormField)
  @IsOptional()
  readonly formDefinition?: GiftFormField[];

  @IsNotEmpty()
  readonly mobilePoints!: number;

  @IsNotEmpty()
  readonly handlerPoints!: number;

  notAllowedAnymore?: boolean;
}
