import { IsNotEmpty, IsOptional } from 'class-validator';
import { StaticContentPage } from './static-content.dto';

export class EditStaticContentDto {
  @IsOptional()
  readonly page?: StaticContentPage;

  @IsOptional()
  readonly contentAr?: string;

  @IsNotEmpty()
  readonly contentFr!: string;
}
