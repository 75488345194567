import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { ProductLocalizedDto } from '../programs';
import { UserDto } from '../users';
import { BasketLocalizedDto } from './basket-localized.dto';

export class BasketProductLocalizedDto {
  @ValidateNested()
  @Type(() => BasketLocalizedDto)
  @IsNotEmpty()
  readonly basket!: BasketLocalizedDto;

  @ValidateNested()
  @Type(() => ProductLocalizedDto)
  @IsNotEmpty()
  readonly product!: ProductLocalizedDto;

  @IsNotEmpty()
  readonly declaredQuantity!: number;

  @IsOptional()
  readonly validatedQuantity?: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;
}
