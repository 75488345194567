import {
  IsNotEmpty,
  IsNumberString,
  Matches,
  MaxLength,
  MinLength,
} from 'class-validator';

export class VerifyMobilePhoneDto {
  @IsNotEmpty()
  password!: string;

  @MinLength(10)
  @MaxLength(10)
  @IsNumberString()
  @Matches(/^0[6-7][0-9]{8}$/)
  @IsNotEmpty()
  readonly mobilePhone!: string;
}
