import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HandlerApi } from '@be-green/api-services';
import { HandlerDto } from '@be-green/dto';
import {
  ErrorDialogComponent,
  ErrorService,
  InputValidationService,
  SeoService,
} from '@be-green/ui-services';
import { Message, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { first } from 'rxjs';

@Component({
  selector: 'be-green--supervisor--handlers',
  templateUrl: './handlers.component.html',
  styleUrls: ['./handlers.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HandlersComponent implements OnInit {
  code?: string;
  errorMessage: Message | null = null;
  handler?: HandlerDto;
  handlerForm!: FormGroup;
  isLoading = false;
  isResetPasswordDialogVisible = false;
  isSearchDialogVisible = false;
  isSetMobilePhoneDialogVisible = false;
  loginForm!: FormGroup;

  constructor(
    private readonly dialogService: DialogService,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly handlerApi: HandlerApi,
    private readonly messageService: MessageService,
    private readonly seoService: SeoService,
  ) {}

  ngOnInit(): void {
    this.seoService.setTitle('Handlers');
  }

  /**
   * Convenience getter for easy access to form fields
   */
  get loginFormControls() {
    return this.loginForm.controls as {
      phone: AbstractControl;
    };
  }

  confirmResetPassword() {
    if (!this.handler || !this.handler.phone) {
      return;
    }

    this.isLoading = true;

    this.handlerApi
      .resetPassword(this.handler.code)
      .pipe(first())
      .subscribe({
        complete: async () => {
          this.messageService.add({
            detail: `Mot de passe réinitialisé. Il est maintenant égal au numéro de téléphone`,
            severity: 'success',
            summary: 'Succès',
          });

          this.isResetPasswordDialogVisible = false;
          this.isLoading = false;
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.messageService.add({
            detail: message,
            severity: 'error',
            summary: title,
          });

          this.isResetPasswordDialogVisible = false;
          this.isLoading = false;
        },
      });
  }

  dismissErrorMessage() {
    this.errorMessage = null;
  }

  resetPassword() {
    if (!this.handler || !this.handler.phone) {
      return;
    }

    this.isLoading = false;
    this.isResetPasswordDialogVisible = true;
  }

  searchHandler() {
    if (!this.code) {
      return;
    }

    this.isLoading = true;

    this.handlerApi.getOne(this.code).subscribe({
      next: (handler) => {
        this.handler = handler;

        this.isSearchDialogVisible = false;

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.dialogService.open(ErrorDialogComponent, {
            data: {
              message: 'Le code détaillant n’est pas présent dans la base.',
            },
            header: 'Données introuvables',
            width: '450px',
            contentStyle: {
              'border-bottom-right-radius': '0.375rem',
              'border-bottom-left-radius': '0.375rem',
            },
          });
        } else {
          this.errorService.handleError(error);
        }

        this.isLoading = false;
      },
    });
  }

  showSearchDialog() {
    this.code = undefined;
    this.isSearchDialogVisible = true;
  }

  showSetPhoneDialog() {
    this.loginForm = this.formBuilder.group({
      phone: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.phoneNumber,
          Validators.minLength(10),
          Validators.maxLength(10),
        ]),
      ],
    });

    this.isSetMobilePhoneDialogVisible = true;
  }

  submitForm() {
    if (!this.code || !this.handler) {
      return;
    }

    this.loginForm?.markAllAsTouched();

    if (this.loginForm?.invalid) {
      return;
    }

    this.isLoading = true;

    this.handlerApi
      .updateHandlerMobilePhone(this.handler.code, {
        mobilePhone: this.loginFormControls.phone.value,
      })
      .subscribe({
        next: () => {
          this.handler = {
            ...(this.handler as HandlerDto),
            phone: this.loginFormControls.phone.value,
          };

          this.isSetMobilePhoneDialogVisible = false;
          this.isLoading = false;

          this.messageService.add({
            detail: 'N° de téléphone défini',
            severity: 'success',
            summary: 'Succès',
          });
        },

        error: (error: HttpErrorResponse) => {
          if (error.status === 409) {
            this.dialogService.open(ErrorDialogComponent, {
              data: {
                message: 'Veuillez sélectionner un autre numéro de téléphone.',
              },
              header: 'Données en double',
              width: '450px',
              contentStyle: {
                'border-bottom-right-radius': '0.375rem',
                'border-bottom-left-radius': '0.375rem',
              },
            });
          } else {
            this.errorService.handleError(error);
          }

          this.isLoading = false;
        },
      });
  }
}
