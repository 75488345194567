import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  ValidateNested,
} from 'class-validator';
import { PersonDto } from './person.dto';
import { UserStatusDto } from './user-status.dto';

export const enum UserRole {
  Admin = 'admin',
  Handler = 'handler',
  Supervisor = 'supervisor',
  System = '__system__',
  User = 'user',
}

export class UserDto {
  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly email!: string;

  @IsOptional()
  readonly password?: string;

  @IsOptional()
  readonly accessToken?: string;

  @IsNumber()
  @IsOptional()
  readonly accessTokenExpiry?: number;

  @IsOptional()
  readonly roles?: UserRole[];

  @ValidateNested()
  @Type(() => PersonDto)
  @IsOptional()
  readonly person?: PersonDto;

  @ValidateNested()
  @Type(() => UserStatusDto)
  @IsOptional()
  readonly status?: UserStatusDto;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;
}
