import { Type } from 'class-transformer';
import { IsNotEmpty, ValidateNested } from 'class-validator';

export class DashboardPeriodDto {
  @IsNotEmpty()
  readonly startOfThisPeriod!: Date;

  @IsNotEmpty()
  readonly endOfThisPeriod!: Date;

  @IsNotEmpty()
  readonly startOfLastPeriod!: Date;

  @IsNotEmpty()
  readonly endOfLastPeriod!: Date;
}

export class DashboardProgressionDto {
  @IsNotEmpty()
  readonly thisPeriod!: number;

  @IsNotEmpty()
  readonly lastPeriod!: number;

  @IsNotEmpty()
  readonly periodDifference!: number;

  @IsNotEmpty()
  readonly periodProgression!: number;
}

export class DashboardItemDto {
  @IsNotEmpty()
  readonly total!: number;

  @ValidateNested()
  @Type(() => DashboardProgressionDto)
  @IsNotEmpty()
  readonly progression!: DashboardProgressionDto;
}

export class DashboardDto {
  @ValidateNested()
  @Type(() => DashboardPeriodDto)
  @IsNotEmpty()
  readonly period!: DashboardPeriodDto;

  @ValidateNested()
  @Type(() => DashboardItemDto)
  @IsNotEmpty()
  readonly baskets!: DashboardItemDto;

  @ValidateNested()
  @Type(() => DashboardItemDto)
  @IsNotEmpty()
  readonly recycledQuantity!: DashboardItemDto;

  @ValidateNested()
  @Type(() => DashboardItemDto)
  @IsNotEmpty()
  readonly recycledWeight!: DashboardItemDto;

  @ValidateNested()
  @Type(() => DashboardItemDto)
  @IsNotEmpty()
  readonly users!: DashboardItemDto;
}
