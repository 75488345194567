import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  MaxLength,
} from 'class-validator';

export class EditMaterialDto {
  @IsOptional()
  readonly code?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameFr!: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameAr!: string;

  @MaxLength(6)
  @IsNotEmpty()
  readonly color!: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly imageUrl!: string;

  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsNotEmpty()
  readonly kiloJoulesSavedWhenRecyclingOneGram!: number;

  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsNotEmpty()
  readonly hoursOfLightingAllowedByOneKiloJoule!: number;

  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsNotEmpty()
  readonly gramsOfCo2SavedWhenRecyclingOneGram!: number;

  @Type(() => Number)
  @IsNumber()
  @IsPositive()
  @IsNotEmpty()
  readonly kilometersAllowedByOneKiloOfCo2!: number;
}
