import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'be-green--ui--error-dialog',
  template: `<div>
      <div class="flex align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>

        <div>
          <span [innerHTML]="config.data.message"></span>
        </div>
      </div>
    </div>

    <div style="padding-top: 1.5rem; text-align: right">
      <button
        class="p-button-outlined"
        (click)="closeDialog()"
        pButton
        pRipple
        icon="pi pi-check"
        [label]="'be_green__serverErrors.BUTTONS.OK' | translate"
      ></button>
    </div>`,
})
export class ErrorDialogComponent {
  constructor(
    private readonly dynamicDialogRef: DynamicDialogRef,
    readonly config: DynamicDialogConfig,
  ) {}

  closeDialog() {
    this.dynamicDialogRef.close();
  }
}
