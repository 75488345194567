import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { TipLocalizedDto } from './tip-localized.dto';

export class CategoryLocalizedDto {
  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly name!: string;

  @IsNotEmpty()
  readonly description!: string;

  @IsNotEmpty()
  readonly position!: number;

  @ValidateNested()
  @Type(() => TipLocalizedDto)
  @IsOptional()
  readonly tips?: TipLocalizedDto[];

  /**
   * Specific to auth'd user
   * -----------------------
   * UI-only
   */
  createdAt?: Date;
  progress?: number;
}
