import { Type } from 'class-transformer';
import {
  IsDecimal,
  IsHexColor,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
} from 'class-validator';
import { ProductDto } from '../programs';

export class MaterialDto {
  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly nameFr!: string;

  @IsOptional()
  readonly nameAr?: string;

  @IsNotEmpty()
  @IsHexColor()
  readonly color!: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  @IsNotEmpty()
  @IsDecimal({ locale: 'fr-FR' })
  readonly kiloJoulesSavedWhenRecyclingOneGram!: number;

  @IsNotEmpty()
  @IsDecimal({ locale: 'fr-FR' })
  readonly hoursOfLightingAllowedByOneKiloJoule!: number;

  @IsNotEmpty()
  @IsDecimal({ locale: 'fr-FR' })
  readonly gramsOfCo2SavedWhenRecyclingOneGram!: number;

  @IsNotEmpty()
  @IsDecimal({ locale: 'fr-FR' })
  readonly kilometersAllowedByOneKiloOfCo2!: number;

  @ValidateNested()
  @Type(() => ProductDto)
  @IsOptional()
  readonly products?: ProductDto[];

  /**
   * Specific to auth'd user
   * -----------------------
   * UI-only
   */
  createdAt?: Date;
  updatedAt?: Date;
}
