import { Component, OnInit } from '@angular/core';
import { LanguageCode, LanguageDirection, LanguageDto } from '@be-green/dto';
import {
  ErrorService,
  NetworkService,
  SeoService,
  TranslationService,
} from '@be-green/ui-services';

import { environment } from '../environments/environment';
import { LayoutService } from './layout/layout.service';

@Component({
  selector: 'be-green--supervisor--root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  supportedLanguages: LanguageDto[] = [
    {
      code: LanguageCode.French,
      label: 'Français',
      direction: LanguageDirection.LeftToRight,
    },
  ];

  constructor(
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly networkService: NetworkService,
    private readonly seoService: SeoService,
    private readonly translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translationService
      .init(this.supportedLanguages)
      .then(() => this.initServices());

    this.seoService.registerAuthor('Tellibus');

    const currentYear = new Date().getFullYear();
    this.layoutService.registerLogoName(
      `© 2023${
        currentYear === 2023 ? '' : ' - ' + currentYear
      } Be Green version ${environment.appVersion}`,
    );
    this.layoutService.registerFooterName('Right Execution Daily');
  }

  private initServices() {
    this.errorService.init();
    this.networkService.init();
  }
}
