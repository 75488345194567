<div
  class="layout-wrapper"
  [ngClass]="{
    'layout-overlay': false,
    'layout-static': true,
    'layout-theme-light': true,
    'layout-overlay-sidebar-active': overlayMenuActive,
    'layout-static-sidebar-inactive': staticMenuInactive,
    'layout-mobile-sidebar-active': menuActiveMobile,
    'p-ripple-disabled': false,
    'p-input-filled': false
  }"
>
  <be-green--supervisor--top-bar
    logoName=""
    [topMenuActive]="topMenuActive"
    (notifyToggleTopMenu)="toggleTopMenu($event)"
  ></be-green--supervisor--top-bar>

  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>

    <be-green--supervisor--footer
      [footerName]="layoutService.footerName"
      [logoName]="layoutService.logoName"
    ></be-green--supervisor--footer>
  </div>

  <div class="layout-mask p-component-overlay" *ngIf="menuActiveMobile"></div>
</div>
