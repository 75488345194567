export const enum BasketStatusCode {
  /**
   * Approved by a collector.
   * ------------------------
   * END OF LIFECYCLE.
   */
  Approved = 'APPROVED',

  /**
   * Created.
   * --------
   * START OF LIFECYCLE.
   * Next: VALIDATED
   */
  Draft = 'DRAFT',

  /**
   * Expired.
   * ----------
   * Previous: !APPROVED
   * END OF LIFECYCLE.
   */
  Expired = 'EXPIRED',

  /**
   * Scanned by a collector.
   * -----------------------
   * Previous: VALIDATED
   * Next: APPROVED
   */
  Scanned = 'SCANNED',

  /**
   * Validated.
   * ----------
   * Previous: DRAFT
   * Next: SCANNED
   */
  Validated = 'VALIDATED',
}

export class BasketStatusDto {
  readonly code!: string;

  readonly name!: string;

  readonly description?: string;
}
