import { Type } from 'class-transformer';
import { IsDecimal, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { ProgramLocalizedDto } from '../programs';

export class HandlerLocalizedDto {
  @IsString()
  readonly code!: string;

  @IsOptional()
  @IsString()
  readonly phone?: string;

  @IsNotEmpty()
  @IsString()
  readonly name!: string;

  @IsOptional()
  @IsDecimal()
  readonly latitude?: number;

  @IsOptional()
  @IsDecimal()
  readonly longitude?: number;

  @IsOptional()
  @IsString()
  readonly photoUrl?: string;

  @IsOptional()
  @IsString()
  readonly address?: string;

  @IsOptional()
  readonly usualVisitDays?: Date[];

  @IsOptional()
  @IsString()
  readonly nextVisitDay?: Date;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  hasBeenVisited?: boolean;
  itineraryHandlers?: HandlerLocalizedDto[];
  programs?: ProgramLocalizedDto[];
}
