import { IsNotEmpty, IsOptional } from 'class-validator';
import { NotificationContext } from '../common';
import { RedemptionStatusCode } from './redemption-status.dto';

export class RedemptionNotificationDto {
  @IsNotEmpty()
  readonly context!: NotificationContext;

  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly giftCode!: string;

  @IsNotEmpty()
  readonly programCode!: string;

  @IsOptional()
  readonly programName?: {
    ar: string;
    fr: string;
  };

  @IsNotEmpty()
  readonly statusCode!: RedemptionStatusCode;

  @IsNotEmpty()
  readonly statusName!: string;

  @IsNotEmpty()
  readonly newPoints!: string;

  @IsNotEmpty()
  readonly usedPoints!: string;

  @IsNotEmpty()
  readonly giftName!: {
    ar: string;
    fr: string;
  };
}
