import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { MaterialDto } from '../materials';

export class ProductDto {
  @IsOptional()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly program?: any;

  @IsNotEmpty()
  readonly code!: string;

  @ValidateNested()
  @Type(() => MaterialDto)
  @IsNotEmpty()
  readonly material!: MaterialDto;

  @IsNotEmpty()
  readonly nameFr!: string;

  @IsOptional()
  readonly nameAr?: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  @IsNotEmpty()
  readonly mobilePoints!: number;

  @IsNotEmpty()
  readonly weightInG!: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  declaredQuantity?: number;
}
