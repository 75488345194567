import { Injectable, NgZone } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  currentPosition?: Position;
  private dialogTranslations!: {
    HEADER: string;
    BUTTONS: { CANCEL: string; OK: string };
  };
  hasCurrentPositionSubject: BehaviorSubject<boolean | undefined> =
    new BehaviorSubject(<boolean | undefined>undefined);
  private isOnDevice = false;
  isPermissionRequested = false;

  constructor(
    private readonly alertController: AlertController,
    private readonly ngZone: NgZone,
    private readonly platform: Platform,
    private readonly translateService: TranslateService,
  ) {
    this.isOnDevice = this.platform.is('capacitor');
  }

  get hasCurrentPosition() {
    return this.hasCurrentPositionSubject.value;
  }

  async init(): Promise<void> {
    this.checkPermissions();

    this.translateService
      .get('GENERIC.DIALOGS.GEOLOCATION.PERMISSION_DENIED')
      .subscribe({
        next: async (dialogTranslations) =>
          (this.dialogTranslations = dialogTranslations),
      });
  }

  private async checkPermissions(): Promise<boolean> {
    try {
      const permissions = await Geolocation.checkPermissions();

      if (
        permissions.location === 'denied' &&
        permissions.coarseLocation === 'denied'
      ) {
        this.hasCurrentPositionSubject.next(false);

        return false;
      } else {
        this.hasCurrentPositionSubject.next(true);

        return true;
      }
    } catch (error) {
      this.hasCurrentPositionSubject.next(false);

      return false;
    }
  }

  async getCurrentPosition(): Promise<void> {
    this.isPermissionRequested = true;

    try {
      const isGranted = await this.checkPermissions();

      if (isGranted) {
        this.currentPosition = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        });
      }
    } catch (error) {
      this.hasCurrentPositionSubject.next(false);
    }
  }

  async showLocationErrorDialog() {
    const alert = await this.alertController.create({
      header: this.dialogTranslations.HEADER,
      backdropDismiss: false,
      buttons: [
        {
          text: this.dialogTranslations.BUTTONS.CANCEL,
          role: 'cancel',
        },

        {
          text: this.dialogTranslations.BUTTONS.OK,

          handler: async () => {
            if (this.isOnDevice) {
              await NativeSettings.open({
                optionAndroid: AndroidSettings.ApplicationDetails,
                optionIOS: IOSSettings.App,
              });
            }
          },
        },
      ],
    });

    await alert.present();
  }
}
