import { Type } from 'class-transformer';
import {
  IsDateString,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  MaxLength,
} from 'class-validator';

export class EditProgramDto {
  @IsOptional()
  readonly code?: string;

  @IsOptional()
  readonly isGoodDeal?: boolean;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameFr!: string;

  @MaxLength(140)
  @IsOptional()
  readonly nameAr?: string;

  @MaxLength(10)
  @IsDateString()
  @IsNotEmpty()
  readonly startDate!: string;

  @MaxLength(10)
  @IsDateString()
  @IsNotEmpty()
  readonly endDate!: string;

  @MaxLength(10)
  @IsDateString()
  @IsOptional()
  readonly handlerEndDate?: string;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  readonly minimumProductQuantity!: number;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsOptional()
  readonly maximumProductQuantity?: number;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsOptional()
  readonly maximumUserBasketsPerDay?: number;

  @MaxLength(255)
  @IsNotEmpty()
  readonly summaryFr!: string;

  @MaxLength(255)
  @IsOptional()
  readonly summaryAr?: string;

  @MaxLength(65536)
  @IsNotEmpty()
  readonly descriptionFr!: string;

  @MaxLength(65536)
  @IsOptional()
  readonly descriptionAr?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly imageUrl!: string;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  readonly referrerPoints!: number;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  readonly referredPoints!: number;

  @IsOptional()
  readonly mobileVideoFr?: string;

  @IsOptional()
  readonly mobileVideoAr?: string;

  @IsOptional()
  readonly handlerVideoFr?: string;

  @IsOptional()
  readonly handlerVideoAr?: string;
}
