import { IsNotEmpty, IsOptional, MaxLength } from 'class-validator';

export class EditTipDto {
  @IsOptional()
  readonly code?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameFr!: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameAr!: string;

  @MaxLength(2048)
  @IsNotEmpty()
  readonly contentFr!: string;

  @MaxLength(2048)
  @IsNotEmpty()
  readonly contentAr!: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly imageUrl!: string;
}
