import { IsNotEmpty, IsOptional, MaxLength } from 'class-validator';

export class EditCategoryDto {
  @IsOptional()
  readonly code?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameFr!: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameAr!: string;

  @MaxLength(65536)
  @IsNotEmpty()
  readonly descriptionFr!: string;

  @MaxLength(65536)
  @IsNotEmpty()
  readonly descriptionAr!: string;
}
