import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LookupOwnDetailsResponseDto } from '@be-green/dto';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  user: Observable<LookupOwnDetailsResponseDto | undefined>;
  private userSubject: BehaviorSubject<LookupOwnDetailsResponseDto | undefined>;

  constructor(
    private readonly storageService: StorageService,
    private readonly httpClient: HttpClient,
  ) {
    const user = this.storageService.get('user');

    if (user) {
      this.userSubject = new BehaviorSubject(
        <LookupOwnDetailsResponseDto | undefined>JSON.parse(user),
      );
    } else {
      this.userSubject = new BehaviorSubject(
        <LookupOwnDetailsResponseDto | undefined>undefined,
      );
    }

    this.user = this.userSubject.asObservable();
  }

  get userValue(): LookupOwnDetailsResponseDto | undefined {
    return this.userSubject.value;
  }

  applyLogout() {
    this.storageService.remove('user');
    this.userSubject.next(undefined);
  }

  lookupOwnDetails() {
    return this.httpClient
      .get<{ user: LookupOwnDetailsResponseDto }>(`/users/details`)
      .pipe(map((data) => this.setUser(data.user)));
  }

  private setUser(user: LookupOwnDetailsResponseDto) {
    this.storageService.set('user', user);

    this.userSubject.next(user);
  }
}
