export const enum RedemptionStatusCode {
  /**
   * Approved execution.
   * ------------------------
   * Previous: SCANNED
   * END OF LIFECYCLE.
   */
  Approved = 'APPROVED',

  /**
   * Rejected by a collector.
   * -----------------------
   * Previous: SCANNED
   * END OF LIFECYCLE.
   */
  Rejected = 'REJECTED',

  /**
   * Created.
   * --------
   * START OF LIFECYCLE.
   * Next: SCANNED
   */
  Requested = 'REQUESTED',

  /**
   * Scanned by a collector.
   * -----------------------
   * Previous: REQUESTED
   * Next: APPROVED or REJECTED
   */
  Scanned = 'SCANNED',
}

export class RedemptionStatusDto {
  readonly code!: string;

  readonly name!: string;

  readonly description?: string;
}
