import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticationService,
  ErrorService,
  InputValidationService,
  SeoService,
} from '@be-green/ui-services';
import { Message } from 'primeng/api';
import { first } from 'rxjs';

@Component({
  selector: 'be-green--supervisor--reset-access',
  templateUrl: './reset-access.component.html',
  styleUrls: ['./reset-access.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetAccessComponent implements OnInit {
  errorMessage: Message | null = null;
  resetPasswordForm!: FormGroup;
  password!: string;
  private token?: string;
  submitting = false;

  valCheck: string[] = ['remember'];

  constructor(
    @Inject('APP_VERSION') readonly appVersion: string,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {}

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token') as string;

    if (this.authenticationService.decodedToken || !this.token) {
      this.router.navigate(['/']);
    }

    this.resetPasswordForm = this.formBuilder.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.email,
          Validators.maxLength(64),
        ]),
      ],
      password: [null, Validators.required],
    });

    this.seoService.setTitle('Activation de compte');
  }

  /**
   * Convenience getter for easy access to form fields
   */
  get resetPasswordFormControls() {
    return this.resetPasswordForm.controls as {
      email: AbstractControl;
      password: AbstractControl;
    };
  }

  dismissErrorMessage() {
    this.errorMessage = null;
  }

  submitResetPasswordForm() {
    this.resetPasswordForm.markAllAsTouched();

    if (this.resetPasswordForm.invalid || !this.token) {
      return;
    }

    this.submitting = true;

    this.authenticationService
      .resetAccess({
        email: this.resetPasswordFormControls.email.value,
        password: this.resetPasswordFormControls.password.value,
        token: this.token,
      })
      .pipe(first())
      .subscribe({
        next: () => {
          this.submitting = false;

          this.router.navigate(['login']);
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.errorMessage = {
            summary: title,
            detail: message,
            severity: 'error',
          };

          this.submitting = false;
        },
      });
  }
}
