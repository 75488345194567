import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BasketDto } from '@be-green/dto';
import {
  ActionPerformed,
  LocalNotifications,
} from '@capacitor/local-notifications';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalNotificationsService {
  awokeWithLocalNotification = false;
  isNotifsGrantedSubject: BehaviorSubject<boolean | undefined> =
    new BehaviorSubject(<boolean | undefined>undefined);
  isPermissionRequested = false;

  constructor(
    private readonly ngZone: NgZone,
    private readonly router: Router,
  ) {}

  async init(): Promise<void> {
    this.checkPermissions();

    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (actionPerformed: ActionPerformed) => {
        this.ngZone.run(() => {
          const notification = actionPerformed.notification;

          if (notification.extra && notification.extra.basket) {
            this.awokeWithLocalNotification = true;

            const basket: BasketDto = notification.extra.basket;

            this.router.navigate([
              'tabs',
              'recycle',
              'programs',
              basket.participation.program.code,
              'baskets',
              basket.code,
            ]);
          }
        });
      },
    );
  }

  private async checkPermissions(): Promise<boolean> {
    try {
      const permissions = await LocalNotifications.checkPermissions();

      if (permissions.display === 'denied') {
        this.isNotifsGrantedSubject.next(false);

        return false;
      } else {
        this.isNotifsGrantedSubject.next(true);

        return true;
      }
    } catch (error) {
      this.isNotifsGrantedSubject.next(false);

      return false;
    }
  }

  async removeAll() {
    const pendingNotifications = await LocalNotifications.getPending();

    for (const pendingNotification of pendingNotifications.notifications) {
      await LocalNotifications.cancel({
        notifications: [{ id: pendingNotification.id }],
      });
    }
  }

  async requestPermission() {
    this.isPermissionRequested = true;

    try {
      const isGranted = await this.checkPermissions();

      if (isGranted) {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        LocalNotifications.requestPermissions().then((result) => {
          if (result.display === 'denied') {
            this.isNotifsGrantedSubject.next(false);
          } else {
            this.isNotifsGrantedSubject.next(true);
          }
        });
      }
    } catch (error) {
      this.isNotifsGrantedSubject.next(false);
    }
  }
}
