import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, first, share, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  constructor(private readonly authenticationService: AuthenticationService) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    /**
     * Exempt specific Access Management routes from automatic JWT injection
     */
    if (
      !request.url.startsWith('/') ||
      request.url.startsWith('/auth/bo/') ||
      ['/auth/device', '/auth/login', '/auth/token'].includes(request.url)
    ) {
      return next.handle(request);
    } else {
      // add authorization header with jwt token if available
      const accessToken = this.authenticationService.accessToken;
      const decodedToken = this.authenticationService.decodedToken;

      if (!accessToken || !decodedToken) {
        return throwError(() => {
          return 'TLB__ACCESS_TOKEN_NOT_PRESENT';
        });
      } else {
        if (!this.authenticationService.isTokenExpired(accessToken)) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          return next.handle(request);
        }

        // The token is expired
        return this.authenticationService.renewToken().pipe(
          first(),
          share(),
          switchMap((newAccessToken) => {
            // refresh succeeded
            // resend the request with the new cookie-based token
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${newAccessToken}`,
              },
            });

            return next.handle(request);
          }),
        );
      }
    }
  }
}

export const jwtInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true,
};
