import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'be-green--ui--confirm-on-leave-dialog',
  template: `<div>
      <div class="flex align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>

        <div>
          <span [innerHTML]="config.data?.message"></span>
        </div>
      </div>
    </div>

    <div style="padding-top: 1.5rem; text-align: right">
      <button
        class="p-button-text mr-2"
        (click)="closeDialog()"
        pButton
        pRipple
        icon="pi pi-times"
        [label]="config.data?.cancelButton!"
      ></button>

      <button
        class="p-button-danger"
        type="button"
        (click)="confirm()"
        pButton
        pRipple
        [label]="config.data?.okButton!"
        icon="pi pi-check"
      ></button>
    </div>`,
})
export class ConfirmOnLeaveDialogComponent {
  constructor(
    private readonly dynamicDialogRef: DynamicDialogRef,
    readonly config: DynamicDialogConfig<{
      cancelButton: string;
      message: string;
      okButton: string;
      title: string;
    }>,
  ) {}

  confirm() {
    this.closeDialog('ok');
  }

  closeDialog(action?: 'ok') {
    this.dynamicDialogRef.close(action);
  }
}
