import { IsEmail, IsNotEmpty } from 'class-validator';

export class ResetAccessDto {
  @IsEmail()
  @IsNotEmpty()
  email!: string;

  @IsNotEmpty()
  password!: string;

  @IsNotEmpty()
  token!: string;
}
