import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { TipDto } from './tip.dto';

export class CategoryDto {
  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly nameFr!: string;

  @IsNotEmpty()
  readonly nameAr!: string;

  @IsNotEmpty()
  readonly descriptionFr!: string;

  @IsNotEmpty()
  readonly descriptionAr!: string;

  @IsNotEmpty()
  readonly position!: number;

  @ValidateNested()
  @Type(() => TipDto)
  @IsOptional()
  readonly tips?: TipDto[];

  /**
   * Specific to auth'd user
   * -----------------------
   * UI-only
   */
  createdAt?: Date;
  isExpanded?: boolean;
  progress?: number;
  updatedAt?: Date;
}
