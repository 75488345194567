import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanMatch,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { AuthenticationService } from '@be-green/ui-services';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanMatch {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return this.canMatch(
      <Route>activatedRouteSnapshot,
      this.router.parseUrl(state.url).root.segments,
    );
  }

  canMatch(route: Route, segments: UrlSegment[]): boolean {
    const authCheckSubject = new BehaviorSubject(false);

    if (this.authenticationService.hasRefreshTokenNonce) {
      authCheckSubject.next(true);
    } else {
      this.redirectRoute(segments);

      authCheckSubject.next(false);
    }

    return authCheckSubject.value;
  }

  private redirectRoute(segments: UrlSegment[]) {
    const returnUrl = '/' + segments.map((s) => s.path).join('/');

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: returnUrl },
    });
  }
}
