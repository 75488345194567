import { IsNotEmpty, IsOptional } from 'class-validator';
import { NotificationContext } from '../common';
import { BasketStatusCode } from './basket-status.dto';

export class BasketNotificationDto {
  @IsNotEmpty()
  readonly context!: NotificationContext;

  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly programCode!: string;

  @IsOptional()
  readonly programName?: {
    ar: string;
    fr: string;
  };

  @IsNotEmpty()
  readonly statusCode!: BasketStatusCode;

  @IsOptional()
  readonly expectedPoints?: string;

  @IsOptional()
  readonly validatedPoints?: string;

  @IsOptional()
  readonly validatedQuantity?: string;

  @IsOptional()
  readonly validatedWeight?: string;

  // 'true' or 'false'
  @IsOptional()
  readonly programIsGoodDeal?: string;
}
