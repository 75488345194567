<p-button
  icon="pi pi-search"
  label="Rechercher un détaillant"
  styleClass="w-full mb-4"
  (click)="showSearchDialog()"
></p-button>

<p-messages></p-messages>

<p-dialog
  class="p-fluid"
  header="Rechercher un détaillant"
  [modal]="true"
  [resizable]="false"
  [style]="{ 'min-width': '320px' }"
  [(visible)]="isSearchDialogVisible"
>
  <ng-template pTemplate="content">
    <div class="mb-4">
      <label class="block text-900 text-xl font-medium mb-2" for="code">
        Code détaillant à rechercher
      </label>

      <span class="p-input-icon-right">
        <input
          class="w-full"
          id="code"
          (keyup.enter)="searchHandler()"
          [(ngModel)]="code"
          pInputText
          style="padding: 1rem"
          maxlength="140"
        />

        <i
          class="pi pi-times"
          style="cursor: pointer"
          (click)="code = undefined"
        ></i>
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text p-button-danger"
      icon="pi pi-times"
      label="Annuler"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="isSearchDialogVisible = false"
    ></button>

    <button
      class="p-button-text"
      icon="pi pi-search"
      label="Rechercher"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="searchHandler()"
    ></button>
  </ng-template>
</p-dialog>

<ng-container *ngIf="handler">
  <div class="grid flex frex-wrap">
    <div class="col-12">
      <div class="card text-center">
        <div class="inline-block shadow-4 mb-4" style="line-height: 0">
          <p-image
            src="{{ handler.clonedPhotoUrl ?? handler.photoUrl }}"
            [preview]="true"
            height="100"
            width="100"
            *ngIf="handler.clonedPhotoUrl || handler.photoUrl; else noPicture"
          ></p-image>

          <ng-template #noPicture>
            <p-avatar
              [label]="handler.nameFr.charAt(0)"
              size="xlarge"
            ></p-avatar>
          </ng-template>
        </div>

        <div>
          <ng-container
            *ngTemplateOutlet="handlerHeaderTemplate"
          ></ng-container>
        </div>
      </div>

      <div class="card">
        <div
          class="flex flex-column md:flex-row justify-content-center align-items-center gap-2"
        >
          <div class="text-500 font-bold">N° de téléphone :</div>

          <ng-container *ngIf="handler.phone; else notSet">
            {{ handler.phone }}

            <p-button
              icon="pi pi-pencil"
              label="Modifier"
              styleClass="p-button-warning"
              (click)="showSetPhoneDialog()"
            ></p-button>

            <p-button
              icon="pi pi-lock"
              label="Réinitialiser MDP"
              styleClass="p-button-danger"
              (click)="resetPassword()"
            ></p-button>
          </ng-container>

          <ng-template #notSet>
            <span class="text-pink-600 font-italic">Non défini</span>

            <p-button
              icon="pi pi-plus"
              label="Définir"
              styleClass="p-button-success"
              (click)="showSetPhoneDialog()"
            ></p-button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <ng-template #handlerHeaderTemplate>
    <p-tag [value]="handler.code"></p-tag>

    <div class="font-medium text-3xl text-900">
      {{ handler.nameFr }}
    </div>
  </ng-template>

  <p-dialog
    class="p-fluid"
    header="N° de téléphone"
    [modal]="true"
    [resizable]="false"
    [style]="{ 'min-width': '320px' }"
    [(visible)]="isSetMobilePhoneDialogVisible"
  >
    <ng-template pTemplate="content">
      <ng-container *ngTemplateOutlet="handlerHeaderTemplate"></ng-container>

      <form class="my-4" [formGroup]="loginForm" (ngSubmit)="submitForm()">
        <label
          class="block text-900 text-xl font-medium mb-2"
          for="mobilePhone"
        >
          N° de téléphone
        </label>

        <div class="mb-3">
          <span class="p-input-icon-right">
            <input
              class="w-full"
              id="mobilePhone"
              type="tel"
              formControlName="phone"
              (keyup.enter)="submitForm()"
              pInputText
              [pKeyFilter]="'num'"
              style="padding: 1rem"
              maxlength="10"
            />

            <i
              class="pi pi-times"
              style="cursor: pointer"
              (click)="loginFormControls.phone.setValue('')"
            ></i>
          </span>
        </div>

        <div>
          <be-green--ui--input-error
            [control]="loginFormControls.phone"
            [platform]="'web'"
          ></be-green--ui--input-error>
        </div>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <button
        class="p-button-text p-button-danger"
        icon="pi pi-times"
        label="Annuler"
        pButton
        pRipple
        [loading]="isLoading"
        (click)="isSetMobilePhoneDialogVisible = false"
      ></button>

      <button
        class="p-button-text p-button-success"
        icon="pi pi-check"
        label="Enregistrer"
        pButton
        pRipple
        [loading]="isLoading"
        (click)="submitForm()"
      ></button>
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Confirmation"
    [modal]="true"
    [resizable]="false"
    [style]="{ width: '450px' }"
    [(visible)]="isResetPasswordDialogVisible"
  >
    <div class="flex align-items-center">
      <i
        class="pi pi-exclamation-triangle mr-3 text-red-500"
        style="font-size: 2rem"
      ></i>

      <div class="flex flex-column" *ngIf="handler">
        <div>
          Êtes-vous sûr de vouloir
          <b>réinitialiser le mot de passe</b> du détaillant
          <b>{{ handler.code }}</b> ayant le login <b>{{ handler.phone }}</b
          >&nbsp;?
        </div>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <button
        class="p-button-text"
        pButton
        pRipple
        icon="pi pi-times"
        label="Non"
        (click)="isResetPasswordDialogVisible = false"
      ></button>

      <button
        class="p-button-text p-button-danger"
        pButton
        pRipple
        icon="pi pi-check"
        label="Oui"
        [loading]="isLoading"
        (click)="confirmResetPassword()"
      ></button>
    </ng-template>
  </p-dialog>
</ng-container>
