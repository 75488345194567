import { IsEmail, IsNotEmpty, IsOptional } from 'class-validator';
import { UserRole } from './user.dto';

export class ForgotPasswordDto {
  @IsEmail()
  @IsNotEmpty()
  email!: string;

  @IsOptional()
  readonly role?: UserRole;
}
