import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional } from 'class-validator';
import { StaticContentPage } from './static-content.dto';

export class StaticContentLocalizedDto {
  @IsNotEmpty()
  readonly page!: StaticContentPage;

  @IsNotEmpty()
  readonly content!: string;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;
}
