import { IsEmail, IsIn, IsNotEmpty } from 'class-validator';
import { UserRole } from '.';

export class EditUserDto {
  @IsEmail()
  @IsNotEmpty()
  email!: string;

  @IsNotEmpty()
  firstName!: string;

  @IsNotEmpty()
  lastName!: string;

  @IsIn([UserRole.Admin, UserRole.Supervisor])
  @IsNotEmpty()
  role!: UserRole;
}
