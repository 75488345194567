import { Type } from 'class-transformer';
import {
  IsHexColor,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
} from 'class-validator';
import { ProductLocalizedDto } from '../programs';

export class MaterialLocalizedDto {
  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly name!: string;

  @IsNotEmpty()
  @IsHexColor()
  readonly color!: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  @IsNotEmpty()
  readonly kiloJoulesSavedWhenRecyclingOneGram!: number;

  @IsNotEmpty()
  readonly hoursOfLightingAllowedByOneKiloJoule!: number;

  @IsNotEmpty()
  readonly gramsOfCo2SavedWhenRecyclingOneGram!: number;

  @IsNotEmpty()
  readonly kilometersAllowedByOneKiloOfCo2!: number;

  @ValidateNested()
  @Type(() => ProductLocalizedDto)
  @IsOptional()
  readonly products?: ProductLocalizedDto[];

  /**
   * Specific to auth'd user
   * -----------------------
   * UI-only
   */
  createdAt?: Date;
  updatedAt?: Date;
}
