import { IsNotEmpty, IsOptional } from 'class-validator';

export class TipDto {
  @IsOptional()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly category?: any;

  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly nameFr!: string;

  @IsNotEmpty()
  readonly nameAr!: string;

  @IsNotEmpty()
  readonly contentFr!: string;

  @IsNotEmpty()
  readonly contentAr!: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  /**
   * Specific to auth'd user
   * -----------------------
   * UI-only
   */
  checked?: boolean;
  checkedAt?: Date;
}
