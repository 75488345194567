import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRole } from '@be-green/dto';
import {
  AuthenticationService,
  ErrorService,
  InputValidationService,
  SeoService,
} from '@be-green/ui-services';
import { Message } from 'primeng/api';
import { first } from 'rxjs';

@Component({
  selector: 'be-green--supervisor--login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  errorMessage: Message | null = null;
  loginForm!: FormGroup;
  password!: string;
  private returnUrl!: string;
  submitting = false;

  constructor(
    @Inject('APP_VERSION') readonly appVersion: string,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {}

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';

    if (this.authenticationService.decodedToken) {
      this.returnUrl =
        this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';

      if (this.returnUrl) {
        this.router.navigate([this.returnUrl]);
      } else {
        this.router.navigate(['/']);
      }
    }

    this.loginForm = this.formBuilder.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.email,
          Validators.maxLength(64),
        ]),
      ],
      password: [null, Validators.required],
    });

    this.seoService.setTitle('Login');
  }

  /**
   * Convenience getter for easy access to form fields
   */
  get loginFormControls() {
    return this.loginForm.controls as {
      email: AbstractControl;
      password: AbstractControl;
    };
  }

  dismissErrorMessage() {
    this.errorMessage = null;
  }

  submitLoginForm() {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.invalid) {
      return;
    }

    this.submitting = true;

    this.authenticationService
      .login(
        this.loginFormControls.email.value,
        this.loginFormControls.password.value,
        UserRole.Supervisor,
      )
      .pipe(first())
      .subscribe({
        next: () => {
          this.submitting = false;

          this.router.navigate([this.returnUrl]);
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.errorMessage = {
            summary: title,
            detail: message,
            severity: 'error',
          };

          this.submitting = false;
        },
      });
  }
}
