import { IsNotEmpty, IsOptional } from 'class-validator';
import { UserRole } from './user.dto';

export class EditPersonDto {
  @IsOptional()
  readonly title?: string;

  @IsNotEmpty()
  readonly firstName!: string;

  @IsNotEmpty()
  readonly lastName!: string;

  @IsOptional()
  readonly initials?: string;

  @IsOptional()
  readonly mobilePhone?: string;

  @IsOptional()
  readonly role?: UserRole;
}
