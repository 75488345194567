import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {
  apiInterceptorProvider,
  errorInterceptorProvider,
  jwtInterceptorProvider,
} from '@be-green/ui-interceptors';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';
import { AppLayoutModule } from './app-layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeFr);

export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],

  imports: [
    // Angular Modules
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,

    // Routing
    AppRoutingModule,

    // 3rd-party Libs
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    // TELLIBUS
    AppLayoutModule,
  ],

  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        /**/
      },
      deps: [Sentry.TraceService],
      multi: true,
    },

    { provide: 'API_BASE_URL', useValue: environment.apiBaseUrl },
    { provide: 'API_PAGE_SIZE', useValue: environment.apiPageSize },
    { provide: 'APP_SCOPE', useValue: environment.scope },
    { provide: 'APP_VERSION', useValue: environment.appVersion },
    { provide: 'IMAGE_BASE_URL', useValue: environment.imageBaseUrl },
    {
      provide: 'JWT_REFRESH_TOKEN_NONCE_NAME',
      useValue: environment.jwt.refreshTokenNonce,
    },
    { provide: 'PUBLIC_URL', useValue: environment.publicUrl },
    { provide: 'SOCKET_IO_URL', useValue: environment.socketIoUrl },

    jwtInterceptorProvider,
    apiInterceptorProvider,
    errorInterceptorProvider,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
