import { IsNotEmpty } from 'class-validator';

export class FaqDto {
  @IsNotEmpty()
  readonly position!: number;

  @IsNotEmpty()
  readonly question!: string;

  @IsNotEmpty()
  readonly answer!: string;
}
