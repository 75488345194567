import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { BasketNotificationDto } from '../baskets';
import { NotificationContext } from '../common';
import { ProgramDto, RedemptionNotificationDto } from '../programs';
import { PersonDto } from '../users';

export class HistoryDto {
  @IsNotEmpty()
  readonly context!: NotificationContext;

  // @ValidateNested()
  // @Type(() => BasketNotificationDto | RedemptionNotificationDto)
  @IsOptional()
  readonly data?: BasketNotificationDto | RedemptionNotificationDto;

  @ValidateNested()
  @Type(() => PersonDto)
  @IsNotEmpty()
  readonly person!: PersonDto;

  @ValidateNested()
  @Type(() => ProgramDto)
  @IsOptional()
  readonly program?: ProgramDto;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;
}
