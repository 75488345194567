import { Type } from 'class-transformer';
import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
} from 'class-validator';
import { ProgramDto, RedemptionDto } from '../programs';
import { PersonDto, UserDto } from '../users';

export class ParticipationDto {
  @ValidateNested()
  @Type(() => PersonDto)
  readonly person!: PersonDto;

  @ValidateNested()
  @Type(() => ProgramDto)
  @IsNotEmpty()
  readonly program!: ProgramDto;

  @IsOptional()
  readonly referralCode?: string;

  @IsOptional()
  readonly referralCount?: number;

  @ValidateNested()
  @Type(() => ParticipationDto)
  @IsOptional()
  readonly referrerParticipation?: ParticipationDto;

  @IsOptional()
  readonly points?: number;

  @IsOptional()
  readonly quantity?: number;

  @IsOptional()
  readonly weight?: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => RedemptionDto)
  @IsOptional()
  redemptions?: RedemptionDto[];

  convertedReferrals?: number;
}
