import { Type } from 'class-transformer';
import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
} from 'class-validator';
import { UserRole } from '../users';
import { ProgramDto } from './program.dto';

export const enum GiftFormFieldType {
  FirstName = 'firstName',
  LastName = 'lastName',
  MobilePhone = 'mobilePhone',
  TelecomOperator = 'telecomOperator',
  TopUpType = 'topUpType',
}

export const enum GiftType {
  QrCode = 'qrCode',
  Form = 'form',
}

export const enum TopUpType {
  Dirhams = 'DIRHAMS',
  Star_1 = '*1',
  Star_2 = '*2',
  Star_3 = '*3',
  Star_4 = '*4',
  Star_5 = '*5',
  Star_6 = '*6',
  Star_7 = '*7',
  Star_8 = '*8',
  Star_9 = '*9',
  Star_33 = '*33',
}

export class GiftFormField {
  @IsNotEmpty()
  readonly field!: GiftFormFieldType;

  @IsArray()
  @IsOptional()
  readonly restrictedToList?: string[];
}

export class GiftDto {
  @IsOptional()
  @ValidateNested()
  @Type(() => ProgramDto)
  @IsNotEmpty()
  readonly program!: ProgramDto;

  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly nameFr!: string;

  @IsOptional()
  readonly nameAr?: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  @IsOptional()
  readonly restrictedToRole?: UserRole;

  @IsOptional()
  readonly allowedRedemptions?: number;

  @IsNotEmpty()
  readonly type!: GiftType;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => GiftFormField)
  @IsOptional()
  readonly formDefinition?: GiftFormField[];

  @IsNotEmpty()
  readonly mobilePoints!: number;

  @IsNotEmpty()
  readonly handlerPoints!: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  notAllowedAnymore?: boolean;
}
