import { IsNotEmpty } from 'class-validator';
import { NotificationContext } from '../common';

export class BadgeNotificationDto {
  @IsNotEmpty()
  readonly context!: NotificationContext;

  @IsNotEmpty()
  readonly level!: string;
}
