import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { PersonDto } from '../users';
import { TipDto } from './tip.dto';

export class HabitDto {
  @ValidateNested()
  @Type(() => PersonDto)
  readonly person!: PersonDto;

  @ValidateNested()
  @Type(() => TipDto)
  @IsNotEmpty()
  readonly tip!: TipDto;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;
}
