import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { InputValidationService } from './input-validation.service';

@Component({
  selector: 'be-green--ui--input-error',
  template: `<ng-container *ngIf="errorMessage !== null">
    <ion-note
      *ngIf="platform === 'mobile'; else webTemplate"
      [innerHTML]="errorMessage"
      color="danger"
      slot="error"
    ></ion-note>

    <ng-template #webTemplate>
      <div
        class="flex"
        *ngIf="class; else defaultClassTemplate"
        [ngClass]="class"
      >
        <i class="pi pi-exclamation-triangle mr-2" *ngIf="!hideIcon"></i>

        <span [innerHTML]="errorMessage"></span>
      </div>

      <ng-template #defaultClassTemplate>
        <div class="font-bold text-left p-error">
          <i class="pi pi-exclamation-triangle mr-2" *ngIf="!hideIcon"></i>

          <span [innerHTML]="errorMessage"></span>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>`,
})
export class InputErrorComponent {
  @Input() class: string | undefined;
  @Input() control: AbstractControl | undefined;
  @Input() hideIcon = false;
  @Input() message!: string;
  @Input() messageParams: string | undefined;
  @Input() platform!: 'mobile' | 'web';

  get errorMessage(): string | null {
    if (this.message) {
      // static message
      const params: { [key: string]: string } = {};

      if (this.messageParams) {
        params[this.message] = this.messageParams;
      }

      return InputValidationService.getValidatorErrorMessage(
        this.message,
        params,
      );
    } else if (this.control) {
      // validation error
      for (const propertyName in this.control.errors) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          this.control.errors.hasOwnProperty(propertyName) &&
          this.control.touched
        ) {
          return InputValidationService.getValidatorErrorMessage(
            propertyName,
            this.control.errors[propertyName],
          );
        }
      }
    }

    return null;
  }
}
