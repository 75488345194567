// https://github.com/Cap-go/capacitor-native-biometric
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionSheetController,
  AlertController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AvailableResult, NativeBiometric } from 'capacitor-native-biometric';
import { BehaviorSubject } from 'rxjs';

import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class BiometryService {
  private isBiometricAuthDesiredSubject!: BehaviorSubject<boolean>;
  private isBiometricAuthPossibleSubject!: BehaviorSubject<boolean | undefined>;
  private biometricAuthFlagKey = 'begreen__biometric';
  private credentialsKey = 'begreen__credentials';
  private firstNameKey = 'begreen__first_name';
  private lastNameKey = 'begreen__last_name';
  private translations!: {
    INIT: {
      HEADER: string;
      TITLE: string;
      BUTTONS: {
        YES: string;
        NO: string;
      };
    };

    SETUP: {
      HEADER: string;
      SUCCESS: string;
      TITLE: string;
    };

    UNSET: {
      HEADER: string;
      TITLE: string;
      BUTTONS: {
        YES: string;
        NO: string;
      };
    };

    VERIFY: {
      HEADER: string;
      TITLE: string;
    };
  };

  constructor(
    private readonly actionSheetController: ActionSheetController,
    private readonly alertController: AlertController,
    private readonly platform: Platform,
    private readonly storageService: StorageService,
    private readonly toastController: ToastController,
    private readonly translateService: TranslateService,
  ) {
    this.verify();
  }

  get isBiometricAuthDesired(): boolean {
    return this.isBiometricAuthDesiredSubject.value;
  }

  get isBiometricAuthPossible(): boolean | undefined {
    return this.isBiometricAuthPossibleSubject.value;
  }

  get storedFirstName(): string {
    return this.storageService.get(this.firstNameKey) || '';
  }

  get storedLastName(): string {
    return this.storageService.get(this.lastNameKey) || '';
  }

  async checkSetup(): Promise<{ u: string; p: string } | null> {
    return new Promise((resolve) => {
      if (this.isBiometricAuthPossible && this.isBiometricAuthDesired) {
        NativeBiometric.verifyIdentity({
          reason: this.translations.VERIFY.HEADER,
          title: this.translations.VERIFY.TITLE,
        }).then(
          async () => {
            resolve(
              (await this.storageService.getSecure(this.credentialsKey)) as {
                u: string;
                p: string;
              },
            );
          },
          () => {
            resolve(null);
          },
        );
      }
    });
  }

  init() {
    this.translateService
      .get('BIOMETRY')
      .subscribe((translations) => (this.translations = translations));
  }

  async setup(
    firstName: string,
    lastName: string,
    credentials: { u: string; p: string },
    reloadUponSuccess = false,
  ): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      if (this.isBiometricAuthPossible) {
        await this.suggestSetup(reloadUponSuccess);

        await this.storageService.setSecure(this.credentialsKey, credentials);

        this.storageService.set(this.firstNameKey, firstName);
        this.storageService.set(this.lastNameKey, lastName);
      }
    } else {
      return Promise.resolve();
    }
  }

  async setupBiometricAuth(): Promise<void> {
    return NativeBiometric.verifyIdentity({
      reason: this.translations.SETUP.HEADER,
      title: this.translations.SETUP.TITLE,
    }).then(
      async () => {
        await this.toastInfo(this.translations.SETUP.SUCCESS);
      },

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error: any) => {
        this.toastError(error.message);
      },
    );
  }

  private async suggestSetup(reloadUponSuccess = false): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translations.INIT.HEADER,
      message: this.translations.INIT.TITLE,

      buttons: [
        {
          text: this.translations.INIT.BUTTONS.NO,
          role: 'cancel',

          handler: async () => {
            if (reloadUponSuccess) {
              location.reload();
            }
          },
        },

        {
          text: this.translations.INIT.BUTTONS.YES,

          handler: async () => {
            await this.setupBiometricAuth();
            this.storageService.set(this.biometricAuthFlagKey, 'true');
            this.isBiometricAuthDesiredSubject.next(true);

            if (reloadUponSuccess) {
              location.reload();
            }
          },
        },
      ],
    });

    await alert.present();
  }

  private async toastError(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 4000,
      color: 'danger',
      animated: true,
      position: 'bottom',
    });
    toast.present();
  }

  private async toastInfo(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 3000,
      color: 'primary',
      animated: true,
      position: 'bottom',
    });
    toast.present();
  }

  async toggleBiometricAuthentication() {
    if (!this.isBiometricAuthPossible) {
      return;
    }

    if (this.isBiometricAuthDesired) {
      return this.unset();
    } else {
      return this.suggestSetup();
    }
  }

  async unset(): Promise<void> {
    if (this.platform.is('ios')) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translations.UNSET.HEADER,
        subHeader: this.translations.UNSET.TITLE,
        buttons: [
          {
            text: this.translations.UNSET.BUTTONS.YES,
            role: 'destructive',
            handler: async () => {
              this.storageService.remove(this.biometricAuthFlagKey);
              this.storageService.removeSecure(this.credentialsKey);
              this.isBiometricAuthDesiredSubject.next(false);
            },
          },

          {
            text: this.translations.UNSET.BUTTONS.NO,
            role: 'cancel',
          },
        ],
      });

      await actionSheet.present();
    } else {
      const alert = await this.alertController.create({
        header: this.translations.UNSET.HEADER,
        message: this.translations.UNSET.TITLE,
        cssClass: 'tlb--alert-confirm-danger',
        buttons: [
          {
            text: this.translations.UNSET.BUTTONS.NO,
            role: 'cancel',
          },
          {
            text: this.translations.UNSET.BUTTONS.YES,
            handler: async () => {
              this.storageService.remove(this.biometricAuthFlagKey);
              this.storageService.removeSecure(this.credentialsKey);
              this.isBiometricAuthDesiredSubject.next(false);
            },
          },
        ],
      });

      await alert.present();
    }
  }

  private async verify(): Promise<void> {
    this.isBiometricAuthDesiredSubject = new BehaviorSubject(
      this.storageService.get(this.biometricAuthFlagKey) === 'true',
    );

    this.isBiometricAuthPossibleSubject = new BehaviorSubject(
      <boolean | undefined>undefined,
    );

    if (Capacitor.isNativePlatform()) {
      // Check if biometrics are available and which type is supported
      NativeBiometric.isAvailable().then((result: AvailableResult) => {
        this.isBiometricAuthPossibleSubject.next(result.isAvailable);
      });
    }
  }
}
