import { Type } from 'class-transformer';
import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { ParticipationDto } from '../baskets/participation.dto';
import { UserDto } from '../users';
import { GiftDto, GiftFormFieldType } from './gift.dto';
import { RedemptionStatusDto } from './redemption-status.dto';

export class RedemptionFormValue {
  @IsNotEmpty()
  readonly field!: GiftFormFieldType;

  @IsString()
  @IsNotEmpty()
  readonly value!: string;
}

export class RedemptionDto {
  @IsNotEmpty()
  readonly code!: string;

  @ValidateNested()
  @Type(() => ParticipationDto)
  @IsOptional()
  readonly participation?: ParticipationDto;

  @ValidateNested()
  @Type(() => GiftDto)
  @IsOptional()
  readonly gift?: GiftDto;

  @IsNotEmpty()
  readonly previousPoints!: number;

  @IsNotEmpty()
  readonly points!: number;

  @ValidateNested()
  @Type(() => RedemptionStatusDto)
  @IsOptional()
  readonly status?: RedemptionStatusDto;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => RedemptionFormValue)
  @IsOptional()
  formValues?: RedemptionFormValue[];

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;
}
