import { IsEmail, IsNotEmpty, IsOptional } from 'class-validator';
import { DeviceApp } from './device.dto';
import { UserRole } from './user.dto';

export class LoginDto {
  @IsEmail()
  @IsNotEmpty()
  readonly username!: string;

  @IsNotEmpty()
  readonly password!: string;

  @IsOptional()
  readonly iss?: DeviceApp;

  @IsOptional()
  readonly aud?: string;

  @IsOptional()
  readonly role?: UserRole;
}
