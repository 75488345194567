import { Type } from 'class-transformer';
import { IsEmail, IsOptional, ValidateNested } from 'class-validator';
import { DeviceApp } from './device.dto';
import { PersonDto } from './person.dto';
import { UserRole } from './user.dto';

export class AccessTokenDto {
  /**
   * The UUID of the device. Used only for mobile apps.
   */
  @IsOptional()
  readonly aud?: string;

  /**
   * The scope of usage. Used only for mobile apps.
   */
  @IsOptional()
  readonly iss?: DeviceApp;

  /**
   * The user profile.
   */
  @ValidateNested()
  @Type(() => PersonDto)
  @IsOptional()
  readonly profile?: PersonDto;

  /**
   * The role(s) of the user.
   */
  @IsOptional()
  readonly roles?: UserRole[];

  /**
   * The user code.
   */
  @IsOptional()
  readonly sub?: string;

  /**
   * The user email.
   */
  @IsEmail()
  @IsOptional()
  readonly username?: string;
}
