import { IsNotEmpty, IsOptional, MaxLength } from 'class-validator';

export class ArticleDto {
  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly titleFr!: string;

  @IsOptional()
  readonly titleAr?: string;

  @IsNotEmpty()
  readonly date!: Date;

  @MaxLength(255)
  @IsOptional()
  readonly sourceFr?: string;

  @MaxLength(255)
  @IsOptional()
  readonly sourceAr?: string;

  @IsNotEmpty()
  readonly readingTimeInMinutes!: number;

  @IsNotEmpty()
  readonly contentFr!: string;

  @IsOptional()
  readonly contentAr?: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  /**
   * Specific to auth'd user
   * -----------------------
   * UI-only
   */
  createdAt?: Date;
  updatedAt?: Date;
}
