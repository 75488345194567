import { Type } from 'class-transformer';
import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
} from 'class-validator';
// import { ParticipationLocalizedDto } from '../baskets';
import { GiftLocalizedDto } from './gift-localized.dto';
import { RedemptionStatusDto } from './redemption-status.dto';
import { RedemptionFormValue } from './redemption.dto';

export class RedemptionLocalizedDto {
  @IsNotEmpty()
  readonly code!: string;

  // @ValidateNested()
  // @Type(() => ParticipationLocalizedDto)
  @IsOptional()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly participation?: any; // ParticipationLocalizedDto;

  @ValidateNested()
  @Type(() => GiftLocalizedDto)
  @IsOptional()
  readonly gift?: GiftLocalizedDto;

  @IsNotEmpty()
  readonly previousPoints!: number;

  @IsNotEmpty()
  readonly points!: number;

  @ValidateNested()
  @Type(() => RedemptionStatusDto)
  @IsOptional()
  readonly status?: RedemptionStatusDto;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => RedemptionFormValue)
  @IsOptional()
  formValues?: RedemptionFormValue[];

  createdAt?: Date;
  updatedAt?: Date;
}
