import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@be-green/ui-guards';
import { UiServicesModule } from '@be-green/ui-services';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ActivateAccessComponent } from './activate-access/activate-access.component';
import { ErrorComponent } from './error/error.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HandlersComponent } from './handlers/handlers.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ResetAccessComponent } from './reset-access/reset-access.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    component: LayoutComponent,

    children: [
      { path: '', redirectTo: 'handlers', pathMatch: 'full' },

      {
        path: 'handlers',
        component: HandlersComponent,
      },
    ],
  },

  { path: '403', component: AccessDeniedComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'activate-access/:token', component: ActivateAccessComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reset-access/:token', component: ResetAccessComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),

    UiServicesModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
