import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { MaterialLocalizedDto } from '../materials';

export class ProductLocalizedDto {
  @IsOptional()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly program?: any;

  @IsNotEmpty()
  readonly code!: string;

  @ValidateNested()
  @Type(() => MaterialLocalizedDto)
  @IsNotEmpty()
  readonly material!: MaterialLocalizedDto;

  @IsNotEmpty()
  readonly name!: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  @IsNotEmpty()
  readonly mobilePoints!: number;

  @IsNotEmpty()
  readonly weightInG!: number;

  declaredQuantity?: number;
}
