import { Type } from 'class-transformer';
import {
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  MaxLength,
} from 'class-validator';
import { UserRole } from '../users';
import { GiftType } from './gift.dto';

export class EditGiftDto {
  @IsOptional()
  readonly code?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameFr!: string;

  @MaxLength(140)
  @IsOptional()
  readonly nameAr?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly imageUrl!: string;

  @IsOptional()
  readonly restrictedToRole?: UserRole;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsOptional()
  readonly allowedRedemptions?: number;

  @IsNotEmpty()
  readonly type!: GiftType;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  readonly mobilePoints!: number;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  readonly handlerPoints!: number;
}
