import { IsNotEmpty, IsOptional } from 'class-validator';
import { ParameterCode } from './parameter.dto';

export class EditParameterDto {
  @IsOptional()
  readonly code?: ParameterCode;

  @IsNotEmpty()
  readonly value!: object;
}
