import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { IsHexColor } from 'class-validator';
import { endOfDay, endOfYesterday, isAfter, startOfDay } from 'date-fns';
import { distinctUntilChanged } from 'rxjs';
import isDecimal from 'validator/lib/isDecimal';
import isEmail from 'validator/lib/isEmail';
import { FloatLocale } from 'validator/lib/isFloat';
import isInt from 'validator/lib/isInt';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class InputValidationService {
  static config: { [key: string]: string } = {
    afterToday: 'The date must be after today',
    amount: 'Format to respect: 1234 or 1234.56',
    companyIdentifier: 'Format to respect: 123456789000057',
    date: 'Format to respect: dd/mm/yyyy',
    datetime: 'Format to respect: dd/mm/yyyy HH:mm',
    email: 'Format to respect: xxxx@yyy.zz',
    googleMapsEmbed: 'Must be the HTML content of an embedded Google map',
    hexColor: 'Format to respect: #AABBCC',
    iframe: 'Format to respect: <iframe ...></iframe>',
    integer: 'Format to respect: 123',
    isNotInThePast: 'Date must not be in the past',
    landlineNumber:
      'Format to respect: 0521234567 or 0531234567 or 0541234567 or 0551234567',
    max: 'Maximum value allowed: {{ max }}',
    min: 'Minimum allowed value: {{ min }}',
    mobilePhoneNumber: 'Format to respect: 0612345678 or 0712345678',
    phoneNumber: 'Format to respect: 0512345678 or 0612345678 or 0712345678',
    postalCode: 'Format to respect: 12345',
    required: 'Required field',
    time: 'Format to respect: HH:mm',
    url: 'Format to respect: http:// or https://',
    videoUrlEmbed: 'The url must be the link of a video on the web',
  };

  static afterToday(control: AbstractControl) {
    if (
      control.value &&
      ((control.value.match(
        /^(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}$/,
      ) &&
        isAfter(
          <Date>UtilsService.createDateFromDdMmYyyy(control.value),
          new Date(),
        )) ||
        (control.value.match(
          /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/,
        ) &&
          isAfter(
            <Date>UtilsService.createDateFromYyyyDdMm(control.value),
            new Date(),
          )) ||
        (control.value.match(
          /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])T[0-9]{2}:[0-9]{2}$/,
        ) &&
          isAfter(
            <Date>UtilsService.createDateFromYyyyDdMmHhIi(control.value),
            new Date(),
          )))
    ) {
      return null;
    } else {
      return { afterToday: true };
    }
  }

  static amount(control: AbstractControl) {
    if (
      control.value !== null &&
      (isDecimal(control.value.toString()) ||
        isDecimal(control.value.toString(), {
          locale: <FloatLocale>navigator.language,
        }) ||
        isInt(control.value.toString()))
    ) {
      return null;
    } else {
      return { amount: true };
    }
  }

  static hexColor(control: AbstractControl) {
    if (control.value && IsHexColor(control.value)) {
      return null;
    } else {
      return { hexColor: true };
    }
  }

  static companyIdentifier(control: AbstractControl) {
    if (control.value && control.value.match(/^[0-9]{15}$/)) {
      return null;
    } else {
      return { companyIdentifier: true };
    }
  }

  static date(control: AbstractControl) {
    if (
      control.value &&
      ((control.value.match(
        /^(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}$/,
      ) &&
        UtilsService.createDateFromDdMmYyyy(control.value) !== null) ||
        (control.value.match(
          /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/,
        ) &&
          UtilsService.createDateFromYyyyDdMm(control.value) !== null))
    ) {
      return null;
    } else {
      return { date: true };
    }
  }

  static datetime(control: AbstractControl) {
    if (
      control.value &&
      control.value.match(
        /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])T[0-9]{2}:[0-9]{2}$/,
      ) &&
      UtilsService.createDateFromYyyyDdMmHhIi(control.value) !== null
    ) {
      return null;
    } else {
      return { datetime: true };
    }
  }

  static dateRange(formGroup: FormGroup) {
    if (
      (!formGroup.get('startDate')?.value &&
        !formGroup.get('endDate')?.value) ||
      (formGroup.get('startDate')?.value &&
        formGroup.get('endDate')?.value &&
        ((formGroup
          .get('startDate')
          ?.value.match(
            /^(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}$/,
          ) &&
          formGroup
            .get('endDate')
            ?.value.match(
              /^(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}$/,
            ) &&
          isAfter(
            endOfDay(
              <Date>(
                UtilsService.createDateFromDdMmYyyy(
                  formGroup.get('endDate')?.value,
                )
              ),
            ),
            startOfDay(
              <Date>(
                UtilsService.createDateFromDdMmYyyy(
                  formGroup.get('startDate')?.value,
                )
              ),
            ),
          )) ||
          (formGroup
            .get('startDate')
            ?.value.match(
              /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/,
            ) &&
            formGroup
              .get('endDate')
              ?.value.match(
                /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/,
              ) &&
            isAfter(
              endOfDay(
                <Date>(
                  UtilsService.createDateFromYyyyDdMm(
                    formGroup.get('endDate')?.value,
                  )
                ),
              ),
              startOfDay(
                <Date>(
                  UtilsService.createDateFromYyyyDdMm(
                    formGroup.get('startDate')?.value,
                  )
                ),
              ),
            ))))
    ) {
      return null;
    } else {
      return { dateRange: true };
    }
  }

  static datetimeRange(formGroup: FormGroup) {
    if (
      (!formGroup.get('startDate')?.value &&
        !formGroup.get('endDate')?.value) ||
      (formGroup.get('startDate')?.value &&
        formGroup.get('endDate')?.value &&
        formGroup
          .get('startDate')
          ?.value.match(
            /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])T[0-9]{2}:[0-9]{2}$/,
          ) &&
        formGroup
          .get('endDate')
          ?.value.match(
            /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])T[0-9]{2}:[0-9]{2}$/,
          ) &&
        isAfter(
          <Date>(
            UtilsService.createDateFromYyyyDdMmHhIi(
              formGroup.get('endDate')?.value,
            )
          ),
          <Date>(
            UtilsService.createDateFromYyyyDdMmHhIi(
              formGroup.get('startDate')?.value,
            )
          ),
        ))
    ) {
      return null;
    } else {
      return { datetimeRange: true };
    }
  }

  static email(control: AbstractControl) {
    if (control.value && isEmail(control.value)) {
      return null;
    } else {
      return { email: true };
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const message = this.config[validatorName];

    if (
      typeof validatorValue === 'object' &&
      Object.prototype.hasOwnProperty.call(validatorValue, validatorName)
    ) {
      return message.replace(
        new RegExp('{{ ' + validatorName + ' }}', 'g'),
        validatorValue[validatorName],
      );
    } else {
      return message;
    }
  }

  static googleMapsEmbed(control: AbstractControl) {
    if (
      !control.value ||
      control.value.match(
        new RegExp(
          '<iframe src="https://www.google.com/maps/embed\\?pb=(.+)</iframe>',
        ),
      )
    ) {
      return null;
    } else {
      return { googleMapsEmbed: true };
    }
  }

  static iframe(control: AbstractControl) {
    if (
      control.value &&
      control.value.match(new RegExp('<iframe(.+)</iframe>'))
    ) {
      return null;
    } else {
      return { iframe: true };
    }
  }

  static init(translations: { [key: string]: string }) {
    if (typeof translations === 'object') {
      for (const key in translations) {
        if (translations[key]) {
          this.config[key] = translations[key];
        }
      }
    }
  }

  static integer(control: AbstractControl) {
    if (control.value !== null && isInt(control.value.toString())) {
      return null;
    } else {
      return { integer: true };
    }
  }

  static isNotInThePast(control: AbstractControl) {
    if (
      control.value &&
      ((control.value.match(
        /^(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}$/,
      ) &&
        isAfter(
          <Date>UtilsService.createDateFromDdMmYyyy(control.value),
          endOfYesterday(),
        )) ||
        (control.value.match(
          /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/,
        ) &&
          isAfter(
            <Date>UtilsService.createDateFromYyyyDdMm(control.value),
            endOfYesterday(),
          )))
    ) {
      return null;
    } else {
      return { isNotInThePast: true };
    }
  }

  static landlineNumber(control: AbstractControl) {
    if (control.value && control.value.match(/^05[2,3,4,5][0-9]{7}$/)) {
      return null;
    } else {
      return { landlineNumber: true };
    }
  }

  static mobilePhoneNumber(control: AbstractControl) {
    if (control.value && control.value.match(/^0[6-7][0-9]{8}$/)) {
      return null;
    } else {
      return { mobilePhoneNumber: true };
    }
  }

  static phoneNumber(control: AbstractControl) {
    if (control.value && control.value.match(/^0[5-7][0-9]{8}$/)) {
      return null;
    } else {
      return { phoneNumber: true };
    }
  }

  static postalCode(control: AbstractControl) {
    if (control.value && control.value.match(/^[0-9]{5}$/)) {
      return null;
    } else {
      return { postalCode: true };
    }
  }

  static setOptionalValidator(
    control: AbstractControl,
    validator: ValidatorFn,
  ) {
    control.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value) {
        control.setValidators(validator);
      } else {
        control.clearValidators();
      }

      control.updateValueAndValidity();
    });
  }

  static time(control: AbstractControl) {
    const isValidTime = (time: string): boolean => {
      const [hours, minutes] = time.split(':').map((n) => parseInt(n));

      return hours <= 23 && minutes <= 59;
    };

    if (
      control.value &&
      control.value.match(/^[0-9]{2}:[0-9]{2}$/) &&
      isValidTime(control.value)
    ) {
      return null;
    } else {
      return { time: true };
    }
  }

  static url(control: AbstractControl) {
    if (
      control.value &&
      control.value
        .toLowerCase()
        .match(
          new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),
        )
    ) {
      return null;
    } else {
      return { url: true };
    }
  }

  static videoUrlEmbed(control: AbstractControl) {
    if (
      !control.value ||
      UtilsService.generateEmbedUrlFromVideoUrl(control.value) !== undefined
    ) {
      return null;
    } else {
      return { videoUrlEmbed: true };
    }
  }
}
