import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { PersonDto } from '@be-green/dto';
import { AuthenticationService, ErrorService } from '@be-green/ui-services';
import { tap } from 'rxjs';

@Component({
  selector: 'be-green--supervisor--top-bar',
  templateUrl: './top-bar.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TopBarComponent {
  @Input() logoName!: string;
  @Input() profile!: PersonDto;
  @Input() topMenuActive!: boolean;

  @Output() notifyToggleTopMenu = new EventEmitter<MouseEvent>();

  constructor(
    readonly authenticationService: AuthenticationService,
    private readonly errorService: ErrorService,
  ) {}

  toggleTopMenu(event: MouseEvent) {
    this.notifyToggleTopMenu.emit(event);
  }

  logout() {
    this.authenticationService
      .logout()
      .pipe(tap(() => location.reload()))
      .subscribe({
        error: (error: HttpErrorResponse) =>
          this.errorService.handleError(error),
      });
  }
}
