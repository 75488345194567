import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRole } from '@be-green/dto';
import {
  AuthenticationService,
  ErrorService,
  InputValidationService,
  SeoService,
} from '@be-green/ui-services';
import { Message, MessageService } from 'primeng/api';
import { first } from 'rxjs';

@Component({
  selector: 'be-green--supervisor--forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
  errorMessage: Message | null = null;
  forgotPasswordForm!: FormGroup;
  submitting = false;

  constructor(
    @Inject('APP_VERSION') readonly appVersion: string,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {}

  ngOnInit(): void {
    if (this.authenticationService.decodedToken) {
      this.router.navigate(['/']);
    }

    this.forgotPasswordForm = this.formBuilder.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.email,
          Validators.maxLength(64),
        ]),
      ],
    });

    this.seoService.setTitle('Mot de passe oublié ?');
  }

  /**
   * Convenience getter for easy access to form fields
   */
  get forgotPasswordFormControls() {
    return this.forgotPasswordForm.controls as {
      email: AbstractControl;
    };
  }

  dismissErrorMessage() {
    this.errorMessage = null;
  }

  submitForgotPasswordForm() {
    this.forgotPasswordForm.markAllAsTouched();

    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.submitting = true;

    this.authenticationService
      .forgotPassword(
        this.forgotPasswordFormControls.email.value,
        UserRole.Supervisor,
      )
      .pipe(first())
      .subscribe({
        next: () => {
          this.submitting = false;

          this.messageService.add({
            detail: `Veuillez vérifier votre boite de réception l’adresse e-mail « ${this.forgotPasswordFormControls.email.value} ». Si vous avez déjà un compte, vous y trouverez les instructions nécessaires. N’oubliez pas de vérifier votre dossier spam.`,
            severity: 'success',
            summary: 'Succès',
          });
        },

        error: async (error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.messageService.add({
              detail: `Veuillez vérifier votre boite de réception l’adresse e-mail « ${this.forgotPasswordFormControls.email.value} ». Si vous avez déjà un compte, vous y trouverez les instructions nécessaires. N’oubliez pas de vérifier votre dossier spam.`,
              severity: 'success',
            });
          } else {
            const { title, message } = await this.errorService.handleError(
              error,
            );

            this.errorMessage = {
              summary: title,
              detail: message,
              severity: 'error',
            };
          }

          this.submitting = false;
        },
      });
  }
}
