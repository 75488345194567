export const enum UserStatusCode {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Deactivated = 'DEACTIVATED',
  New = 'NEW',
  Terminated = 'TERMINATED',
}

export class UserStatusDto {
  readonly code!: string;

  readonly name!: string;

  readonly description?: string;
}
