import { Environment } from '../../../environment.interface';

export const environment: Environment = {
  production: true,
  scope: 'admin',
  platform: 'web',

  appVersion: '1.2.0',

  apiBaseUrl: 'https://api.begreen.africa/v1',
  imageBaseUrl: 'https://shared.begreen.africa/',
  socketIoUrl: 'https://api.begreen.africa',

  apiPageSize: 10,

  jwt: { refreshTokenNonce: 'A2UFZ8Jjxp' },

  publicUrl: 'supervisor.begreen.africa',
};
