import { Inject, Injectable } from '@angular/core';
import { LanguageCode, LanguageDto, Scope } from '@be-green/dto';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConfirmOnLeaveGuard } from '../confirm-on-leave/confirm-on-leave.guard';
import { InputValidationService } from '../form-validation';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private _backButtonText = '';
  private _currentLanguage?: LanguageDto;
  private _languageConfig!: LanguageDto[];
  private shouldRelyOnLocalStorage = false;

  constructor(
    @Inject('APP_SCOPE') private readonly appScope: string,
    private authenticationService: AuthenticationService,
    private readonly confirmOnLeaveGuard: ConfirmOnLeaveGuard,
    private readonly localStorageService: LocalStorageService,
    private readonly platform: Platform,
    private readonly primeNgConfig: PrimeNGConfig,
    private readonly translateService: TranslateService,
  ) {
    this.shouldRelyOnLocalStorage =
      this.platform.is('android') || !this.platform.is('capacitor');
  }

  get backButtonText() {
    return this._backButtonText;
  }

  get currentLanguage() {
    return this._currentLanguage;
  }

  get languageConfig() {
    return this._languageConfig;
  }

  private getVerifiedLanguage(languageCode: string): LanguageDto {
    this._currentLanguage = this._languageConfig.find(
      (language) => language.code === languageCode,
    );

    if (!this._currentLanguage) {
      this._currentLanguage = this._languageConfig[0];
    }

    return this._currentLanguage;
  }

  async init(supportedLanguages: LanguageDto[]) {
    this.registerLanguages(supportedLanguages);
    let languageCode: LanguageCode = supportedLanguages[0].code;

    if (this.localStorageService.storedLanguage.toString() !== '') {
      languageCode = this.localStorageService.storedLanguage;
    } else if (Capacitor.isNativePlatform()) {
      // In fact, this gets the device language (intersected with pList preferences on iOS)
      // on iOS, language in the app settings, it also returns the correct language
      languageCode = (await Device.getLanguageCode()).value as LanguageCode;
    } else {
      languageCode = navigator.language.split('-')[0] as LanguageCode;
    }

    this.translate(languageCode);
  }

  private registerLanguages(languageConfig: LanguageDto[]) {
    this._languageConfig = languageConfig;
  }

  setBackButtonText(text: string): void {
    this._backButtonText = text;
  }

  translate(languageCode: LanguageCode, reload = false) {
    const language = this.getVerifiedLanguage(languageCode);
    languageCode = language.code;

    this.translateService.use(languageCode).subscribe(() => {
      if (this.appScope === Scope.Mobile) {
        this.authenticationService.upsertLanguageCode(languageCode).subscribe();
      }

      document.documentElement.dir = language.direction;

      if (this.shouldRelyOnLocalStorage) {
        this.localStorageService.storeLanguage(languageCode);

        if (reload) {
          location.reload();
        }
      }

      if (this.platform.is('ios')) {
        this.translateService
          .get('GENERIC.BUTTONS.BACK')
          .subscribe((res: string) => this.setBackButtonText(res));
      }

      this.translateService
        .get('primeng')
        .subscribe((res) => this.primeNgConfig.setTranslation(res));

      this.translateService
        .get('be_green__inputValidation')
        .subscribe((res) => InputValidationService.init(res));

      this.translateService
        .get('be_green__confirmOnLeave')
        .subscribe((res) => this.confirmOnLeaveGuard.init(res));
    });
  }
}
