import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { ProgramLocalizedDto } from '../programs';
import { UserDto } from '../users';
import { HandlerLocalizedDto } from './handler-localized.dto';

export class ProgramHandlerLocalizedDto {
  @ValidateNested()
  @Type(() => ProgramLocalizedDto)
  @IsNotEmpty()
  readonly program!: ProgramLocalizedDto;

  @ValidateNested()
  @Type(() => HandlerLocalizedDto)
  @IsNotEmpty()
  readonly handler!: HandlerLocalizedDto;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;
}
