import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BypassSecurityTrustHtmlPipe } from './bypass-security-trust-html/bypass-security-trust-html.pipe';
import { BypassSecurityTrustResourceUrlPipe } from './bypass-security-trust-html/bypass-security-trust-resource-url.pipe';
import { StringPipe } from './bypass-security-trust-html/string.pipe';
import { CustomDatePipe } from './custom-date.pipe';
import { ReversePipe } from './reverse/reverse.pipe';
import { TimeAgoPipe } from './time-ago/time-ago.pipe';
import { TimesPipe } from './times/times.pipe';

@NgModule({
  imports: [CommonModule],

  declarations: [
    BypassSecurityTrustHtmlPipe,
    BypassSecurityTrustResourceUrlPipe,
    CustomDatePipe,
    ReversePipe,
    StringPipe,
    TimeAgoPipe,
    TimesPipe,
  ],

  exports: [
    BypassSecurityTrustHtmlPipe,
    BypassSecurityTrustResourceUrlPipe,
    CustomDatePipe,
    ReversePipe,
    StringPipe,
    TimeAgoPipe,
    TimesPipe,
  ],
})
export class UiPipesModule {}
