import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { ConfirmOnLeaveDialogComponent } from './confirm-on-leave-dialog.component';

export interface ComponentCanDeactivate {
  isDirty: () => boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmOnLeaveGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  private readonly _config = {
    cancelButton: 'Stay on page',
    message:
      'You have unsaved changes: are you sure you want to proceed? <b>Your changes will be lost!</b>',
    okButton: 'Leave page',
    title: 'Are you sure you want to leave this page?',
  };

  constructor(private readonly dialogService: DialogService) {}

  init(translations: { [key: string]: string }) {
    if (typeof translations === 'object') {
      for (const key in translations) {
        if (translations[key]) {
          this._config[key as 'message' | 'title'] = translations[key];
        }
      }
    }
  }

  canDeactivate(
    component: ComponentCanDeactivate,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Observable((observer) => {
      if (component.isDirty()) {
        const dynamicDialogRef = this.dialogService.open(
          ConfirmOnLeaveDialogComponent,
          {
            closable: false,
            data: this._config,
            header: this._config.title,
            width: '450px',
            contentStyle: {
              'border-bottom-right-radius': '0.375rem',
              'border-bottom-left-radius': '0.375rem',
            },
          },
        );

        dynamicDialogRef.onClose.subscribe((action: 'ok' | undefined) => {
          if (action === 'ok') {
            observer.next(true);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
