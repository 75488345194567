import { Type } from 'class-transformer';
import {
  IsDateString,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  MaxLength,
} from 'class-validator';

export class EditArticleDto {
  @IsOptional()
  readonly code?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly titleFr!: string;

  @MaxLength(140)
  @IsOptional()
  readonly titleAr?: string;

  @MaxLength(10)
  @IsDateString()
  @IsNotEmpty()
  readonly date!: string;

  @MaxLength(255)
  @IsOptional()
  readonly sourceFr?: string;

  @MaxLength(255)
  @IsOptional()
  readonly sourceAr?: string;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  readonly readingTimeInMinutes!: number;

  @MaxLength(65536)
  @IsNotEmpty()
  readonly contentFr!: string;

  @MaxLength(65536)
  @IsOptional()
  readonly contentAr?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly imageUrl!: string;
}
