import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { ProductDto } from '../programs';
import { UserDto } from '../users';
import { BasketDto } from './basket.dto';

export class BasketProductDto {
  @ValidateNested()
  @Type(() => BasketDto)
  @IsNotEmpty()
  readonly basket!: BasketDto;

  @ValidateNested()
  @Type(() => ProductDto)
  @IsNotEmpty()
  readonly product!: ProductDto;

  @IsNotEmpty()
  readonly declaredQuantity!: number;

  @IsOptional()
  readonly validatedQuantity?: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;
}
