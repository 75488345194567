import { IsNotEmpty } from 'class-validator';

export const enum LanguageCode {
  Arabic = 'ar',
  French = 'fr',
}

export const enum LanguageDirection {
  LeftToRight = 'ltr',
  RightToLeft = 'rtl',
}

export class LanguageDto {
  @IsNotEmpty()
  readonly code!: LanguageCode;

  @IsNotEmpty()
  readonly label!: string;

  @IsNotEmpty()
  readonly direction!: LanguageDirection;
}
