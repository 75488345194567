import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HandlerDto,
  HandlerLocalizedDto,
  LanguageCode,
  QueryUiDto,
  UpdateHandlerMobilePhoneDto,
} from '@be-green/dto';
import { AuthenticationService, UtilsService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HandlerApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  export(): Observable<Blob> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get(`/handlers/export`, {
      responseType: 'blob',
    });
  }

  getAll(
    queryUiDto: QueryUiDto,
    programCode?: string,
  ): Observable<{ rows: HandlerDto[]; count: number }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<{ rows: HandlerDto[]; count: number }>(
      `/handlers/admin${programCode ? '/program/' + programCode : ''}`,
      {
        params: UtilsService.fromQueryUiDtoToHttpParams(queryUiDto),
      },
    );
  }

  getAllInBounds(
    northEastLatitude: number,
    northEastLongitude: number,
    southWestLatitude: number,
    southWestLongitude: number,
    programCode?: string,
  ): Observable<HandlerDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<HandlerDto[]>(
      `/handlers/admin/${northEastLatitude},${northEastLongitude}-${southWestLatitude},${southWestLongitude}${
        programCode ? '/program/' + programCode : ''
      }`,
    );
  }

  getAllLocalizedInBounds(
    languageCode: LanguageCode,
    northEastLatitude: number,
    northEastLongitude: number,
    southWestLatitude: number,
    southWestLongitude: number,
    programCode?: string,
  ): Observable<HandlerLocalizedDto[]> {
    return this.httpClient.get<HandlerLocalizedDto[]>(
      `/handlers/${languageCode}/${northEastLatitude},${northEastLongitude}-${southWestLatitude},${southWestLongitude}${
        programCode ? '/program/' + programCode : ''
      }`,
    );
  }

  getMyData(languageCode: LanguageCode): Observable<HandlerLocalizedDto> {
    return this.httpClient.get<HandlerLocalizedDto>(
      `/handlers/me/${languageCode}`,
    );
  }

  getOne(code: string): Observable<HandlerDto> {
    if (
      !this.authenticationService.isAdmin &&
      !this.authenticationService.isSupervisor
    ) {
      return of();
    }

    return this.httpClient.get<HandlerDto>(`/handlers/${code}`);
  }

  resetPassword(code: string): Observable<void> {
    if (
      !this.authenticationService.isAdmin &&
      !this.authenticationService.isSupervisor
    ) {
      return of();
    }

    return this.httpClient.post<void>(
      `/handlers/admin/reset-password/${code}`,
      null,
    );
  }

  updateHandlerMobilePhone(
    code: string,
    updateHandlerMobilePhoneDto: UpdateHandlerMobilePhoneDto,
  ): Observable<void> {
    if (!this.authenticationService.isSupervisor) {
      return of();
    }

    return this.httpClient.put<void>(
      `/handlers/supervisor/${code}`,
      updateHandlerMobilePhoneDto,
    );
  }
}
