import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { MaterialDto } from '../materials';
import { PersonDto, UserDto } from '../users';

export class ContributionDto {
  @ValidateNested()
  @Type(() => PersonDto)
  readonly person!: PersonDto;

  @ValidateNested()
  @Type(() => MaterialDto)
  @IsNotEmpty()
  readonly material!: MaterialDto;

  @IsOptional()
  readonly weight?: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;
}
