import { IsNotEmpty, IsOptional } from 'class-validator';

export class ArticleLocalizedDto {
  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly title!: string;

  @IsNotEmpty()
  readonly date!: Date;

  @IsOptional()
  readonly source?: string;

  @IsNotEmpty()
  readonly readingTimeInMinutes!: number;

  @IsNotEmpty()
  readonly content!: string;

  @IsNotEmpty()
  readonly imageUrl!: string;
}
