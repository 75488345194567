import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { ProgramDto } from '../programs';
import { UserDto } from '../users';
import { HandlerDto } from './handler.dto';

export class ProgramHandlerDto {
  @ValidateNested()
  @Type(() => ProgramDto)
  @IsNotEmpty()
  readonly program!: ProgramDto;

  @ValidateNested()
  @Type(() => HandlerDto)
  @IsNotEmpty()
  readonly handler!: HandlerDto;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;
}
