import { Type } from 'class-transformer';
import { IsNumber, ValidateNested } from 'class-validator';
import { UserDto } from './user.dto';

export const enum TokenType {
  Activation = 'activation',
  Reset = 'reset',
  Verification = 'verification',
}

export class TokenDto {
  @ValidateNested()
  @Type(() => UserDto)
  readonly user!: UserDto;

  readonly type!: TokenType;

  readonly code!: string;

  @IsNumber()
  readonly expiry!: number;

  @Type(() => Date)
  readonly createdAt!: Date;

  @ValidateNested()
  @Type(() => UserDto)
  readonly createdBy!: UserDto;

  @Type(() => Date)
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  readonly deletedBy?: UserDto;
}
