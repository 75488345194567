import { Type } from 'class-transformer';
import {
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  MaxLength,
} from 'class-validator';

export class EditProductDto {
  @IsOptional()
  readonly code?: string;

  @IsNotEmpty()
  readonly material!: { code: string };

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameFr!: string;

  @MaxLength(140)
  @IsOptional()
  readonly nameAr?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly imageUrl!: string;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  readonly mobilePoints!: number;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  readonly weightInG!: number;
}
