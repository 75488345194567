import { Inject, Injectable, NgZone } from '@angular/core';
import { Scope } from '@be-green/dto';
import { Network } from '@capacitor/network';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private dialogTranslations!: {
    MESSAGE: string;
    BUTTONS: { OK: string };
  };
  isNetworkConnectedSubject: BehaviorSubject<boolean | undefined> =
    new BehaviorSubject(<boolean | undefined>undefined);
  toast?: HTMLIonToastElement;

  constructor(
    @Inject('APP_SCOPE') private readonly appScope: string,
    private readonly ngZone: NgZone,
    private readonly toastController: ToastController,
    private readonly translateService: TranslateService,
  ) {}

  async init() {
    this.translateService
      .get('GENERIC.DIALOGS.NETWORK.DISCONNECTED')
      .subscribe({
        next: async (dialogTranslations) => {
          this.dialogTranslations = dialogTranslations;

          if (this.appScope === Scope.Mobile) {
            /**
             * Initial status
             */
            this.changeStatus((await Network.getStatus()).connected);
          } else {
            /**
             * Initial status
             */
            this.changeStatus(window.navigator.onLine);
          }
        },
      });

    if (this.appScope === Scope.Mobile) {
      Network.addListener('networkStatusChange', (status) => {
        this.ngZone.run(() => this.changeStatus(status.connected));
      });
    } else {
      window.addEventListener('offline', () => {
        this.changeStatus(false);
      });

      window.addEventListener('online', () => {
        this.changeStatus(true);
      });
    }
  }

  private async changeStatus(connected: boolean) {
    this.isNetworkConnectedSubject.next(connected);

    if (connected) {
      if (this.appScope === Scope.Mobile) {
        this.toast?.dismiss();
      }
    } else {
      if (this.appScope === Scope.Mobile) {
        this.toast = await this.toastController.create({
          message: this.dialogTranslations.MESSAGE,
          color: 'danger',
          position: 'bottom',
          buttons: [
            {
              text: this.dialogTranslations.BUTTONS.OK,
              role: 'cancel',
            },
          ],
        });

        await this.toast.present();
      } else {
        alert(this.dialogTranslations.MESSAGE);
      }
    }
  }
}
