import { IsNotEmpty, IsOptional } from 'class-validator';

export class IconDto {
  @IsOptional()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly program?: any;

  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly imageUrl!: string;
}
